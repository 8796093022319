<template>
  <div class="header">
    <div class="mob__header pl-3 pr-3">
      <div class="d-flex justify-content-between align-items-center pb-3">
        <div>
          <input id="menu__toggle" type="checkbox" />
          <label class="menu__btn" for="menu__toggle">
            <span></span>
          </label>

          <ul class="menu__box">

            <div class="sitelogomob">

              <img src="@/assets/img/newlogo.svg" alt="">
            </div>

            <hr>

            <div class="mobuser d-flex align-items-center justify-content-center">

              <a href="/account"> <img src="../assets/img/avatar.png" alt="" style="" class="accountHeaderImgM" /></a>

              <div>
                <p class="ml-3 m-0">{{ userName }}</p>
                <div class="d-flex align-items-center ml-3">
                  <img class="imgwal" src="../assets/img/wallet.png" alt="">
                  <span class="ml-2">{{ userBalance }} ₸</span>
                </div>
              </div>

            </div>

            <hr>


            <li><a class="menu__item mt-4" href="/">Главная</a></li>
            <li><a class="menu__item" href="/sale">Продажа</a></li>
            <li><a class="menu__item" href="/shop">Магазин</a></li>
            <li><a class="menu__item" href="/#FAQ">FAQ</a></li>
            <li><a class="menu__item" data-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false"
                aria-controls="collapseExample2" @click="collapseRules()">Правила <img id="imgmenu"
                  src="@/assets/img/menuarrow.svg" alt=""></a></li>
            <div class="collapse" id="collapseExample2">
              <div class="card card-body">
                <a href="/terms">Пользовательское соглашение</a>
                <a href="/privacy" class="mt-4">Политика конфиденциальности</a>
              </div>
            </div>

            <hr>
            <div class="pl-4 pr-4 mt-4">
              <small>
                Товарищество с ограниченной ответственностью "DGroup2020", 201140036434, Казахстан, город Астана, район
                Есиль, улица Сауран, дом 42, н.п. 3, почтовый индекс 010000

              </small>
            </div>
          </ul>
        </div>
        <form action="https://steamcommunity.com/openid/login" method="post"
          style="position:fixed; right:5%; top: 0%; z-index: 10000" v-if="!IsLogged">
          <input type="hidden" name="openid.identity" value="http://specs.openid.net/auth/2.0/identifier_select" />
          <input type="hidden" name="openid.claimed_id" value="http://specs.openid.net/auth/2.0/identifier_select" />
          <input type="hidden" name="openid.ns" value="http://specs.openid.net/auth/2.0" />
          <input type="hidden" name="openid.mode" value="checkid_setup" />
          <input type="hidden" name="openid.realm" value="https://megacase.kz/" />
          <input type="hidden" name="openid.return_to" value="https://megacase.kz/error" />
          <button class="mt-3 steammob d-flex align-items-center"><img src="../assets/img/steamLog.png" class="mr-2"
              alt=""> Войти
            через Steam</button>
        </form>

      </div>
    </div>
    <div class="pc__header">
      <div>
        <router-link to="/" @click="trackPage('/')"><img src="../assets/img/newlogo.svg" alt="logo"
            class="logo__img" /></router-link>
      </div>
      <the-navbar></the-navbar>
      <div class="navbar" v-if="IsLogged">
        <div @click="openHeader()" class="cartCont">
          <img src="../assets/img/cartImg.png" alt="" style="width:2.08vw">
          <div class="cartCounter">
            {{ cartSum }}
          </div>
        </div>
        <div class="user">
          <router-link to="/account" @click="trackPage('/account')">
            <img src="../assets/img/avatar.png" alt="" style="width: 3.13vw" class="accountHeaderImgPC" />
          </router-link>
          <div class="dropdown">
            <img src="../assets/img/avatar.png" alt="" style="" class="accountHeaderImgM" />
            <div class="prodileCont">
              <div class="name">{{ userName }}</div>
              <div class="walletCont">
                <img class="walletImg" src="../assets/img/wallet.png" alt="" style="width:1.25vw">
                <div class="purse__count">{{ userBalance }} ₸</div>
              </div>
            </div>

            <div class="dropdown-content">
              <a href="/account">Профиль</a>
              <a @click="logOut">Выйти</a>
            </div>
          </div>
        </div>
      </div>
      <div class="sign__in" v-else>
        <button class="signin" data-toggle="modal" data-target="#loginModal">
          Войти
        </button>
        <form action="https://steamcommunity.com/openid/login" method="post">
          <input type="hidden" name="openid.identity" value="http://specs.openid.net/auth/2.0/identifier_select" />
          <input type="hidden" name="openid.claimed_id" value="http://specs.openid.net/auth/2.0/identifier_select" />
          <input type="hidden" name="openid.ns" value="http://specs.openid.net/auth/2.0" />
          <input type="hidden" name="openid.mode" value="checkid_setup" />
          <input type="hidden" name="openid.realm" value="https://megacase.kz/" />
          <input type="hidden" name="openid.return_to" value="https://megacase.kz/error" />
          <button class="signin__steam">
            <img src="../assets/img/steamLog.png" alt="" class="steam__img" />
            <div class="steam__text">Войти через Steam</div>
          </button>
        </form>

      </div>
    </div>
  </div>
  <cart-menu>
  </cart-menu>
  <login-modal></login-modal>
  <reg-modal></reg-modal>
</template>

<script>


import CartMenu from '../views/CartMenu.vue'
import { mapGetters, mapActions } from 'vuex'
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      userName: null,
      userBalance: null,
    };
  },
  components: {
    CartMenu
  },

  methods: {
    collapseRules() {
      let menu = document.querySelector('#collapseExample2')
      let img = document.querySelector('#imgmenu')

      if (!menu.classList.contains('show')) {
        img.classList.add('imgactive')
      }
      else {
        img.classList.remove('imgactive')
      }
    },
    ...mapActions(["LogOut"]),
    logOut() {
      localStorage.setItem("userName", '');
      localStorage.setItem("userEmail", '');
      localStorage.setItem("userLoged", '');
      this.LogOut();
    },
    openHeader() {
      let sc = $("#cart")[0];
      sc.style.transition = "all 0.8s";
      sc.style.display = "block";
      sc.style.right = 0;
      sc.style.top = 0;
    },
    topupMoney() {
      $("#Money").modal("show");
    }
  },
  mounted() {
    let header = document.querySelector('.pc__header');
    window.addEventListener('scroll', function () {
      if (window.scrollY > 50) {
        header.style.backgroundColor = 'rgba(30, 29, 33, 1)';
        // header.style.paddingTop = '1vw';
        // header.style.paddingBottom = '0.1vw';

      }
      else {
        header.style.backgroundColor = ''
      }
      // console.log(this.CART_PLS.length)
    });


  },
  created() {

    if (this.IsLogged == 'true') {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);

      this.userName = decoded.name
      this.userBalance = decoded.balance
    }
    else {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);

      this.userName = decoded.e_mail
      this.userBalance = decoded.balance
    }

    if (this.IsLogged == 'true') {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);
      axios({
        method: 'get',
        url: `https://cs-market.kg/api/get_user_openid?open_id=${decoded.open_id}&site_name=megacase`,
      })
        .then((response) => {
          if (response.data.jwt_token) {
            console.log(response.data)
            localStorage.setItem("userName", response.data.jwt_token);

            var token = localStorage.getItem('userName')
            var decoded = jwt_decode(token);

            this.userName = decoded.name
            this.userBalance = decoded.balance
          }
          else {
            return
          }
        })
        .catch((error) => {

          console.error(error);
        })
    }
    else {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);

      const path = "https://cs-market.kg/api/refresh_token";
      axios
        .post(path, { email: decoded.email, password: decoded.password, site_name: 'megacase' })
        .then((res) => {
          console.log(res)
          localStorage.setItem("userName", res.data.jwt_token);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  computed: {
    ...mapGetters(['CART_PLS', 'USER_NAME', 'IsLogged']),
    cartSum() {
      return this.CART_PLS.length
    }
  }

};
</script>

<style scoped>
.steammob {
  background: #2C6DF5;
  border-radius: 5px;
  padding: 10px;
  border: 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--nun);
  color: #fff;


}

.menu__box small {

  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--nun);
  color: rgba(255, 255, 255, 0.3);


}

.imgactive {
  transform: rotate(180deg);
}

li a img {
  margin-left: 50%;
  transition: all .3s ease;
}

.card a {
  font-weight: 200 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-family: var(--unb);
}

.card {
  border: 0 !important;
  background-color: transparent !important;
}

.sitelogomob {
  padding-left: 75px;
}

.menu__box hr {
  background: rgba(255, 255, 255, 0.1);
  opacity: 0.3;
}

.imgwal {
  width: 17px;
}

.mobuser span {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  font-family: var(--unb);
  color: #fff;
}

.mobuser p {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  font-family: var(--nun);
  color: #fff;
}

.menu__box a {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  font-family: var(--unb);
  color: #fff;
}


#menu__toggle {
  opacity: 0;
}

.menu__btn {
  display: flex;
  /* используем flex для центрирования содержимого */
  align-items: center;
  /* центрируем содержимое кнопки */
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 100000000;
  color: #fff;
  position: relative;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.menu__btn>span::before {
  content: '';
  top: -8px;
}

.menu__btn>span::after {
  content: '';
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px 0;
  list-style: none;
  text-align: left;
  background: #2B2E37;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
}

/* элементы меню */
.menu__item {
  display: block;
  padding: 12px 24px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  z-index: 100000;
}

.menu__item:hover {
  background-color: #CFD8DC;
}

#menu__toggle:checked~.menu__btn>span {
  transform: rotate(45deg);
}

#menu__toggle:checked~.menu__btn>span::before {
  top: 0;
  transform: rotate(0);
}

#menu__toggle:checked~.menu__btn>span::after {
  top: 0;
  transform: rotate(90deg);
}

#menu__toggle:checked~.menu__box {
  visibility: visible;
  left: 0;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
  transition-duration: .25s;
}

.menu__box {
  transition-duration: .25s;
  z-index: 100000;
}

.menu__item {
  transition-duration: .25s;
}

.mob__header {
  display: none;
  position: fixed;
  z-index: 10000;
  background: rgb(255 255 255 / 20%);
  width: 100%;

}

.cartCont {
  cursor: pointer;
  position: relative;
}

.cartCont img {
  width: 40px;
}

.cartCounter {
  color: white;
  position: absolute;
  top: -10px;
  right: -10px;
  background: #AA001A;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 16px;
}

.accountHeaderImgPC {}

.prodileCont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}

.walletCont {
  display: flex;
  align-items: center;
}

.walletImg {
  width: 24px;
}

.steam__img {
  width: 1.250vw;
}

.plusik {
  position: absolute;
  top: 0.36vw;
  left: 3.18vw;
}

.user p {
  margin-bottom: 0 !important;
}

.navbar {
  align-items: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  text-align: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #202025;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(170, 0, 26, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  font-family: var(--nun);
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: rgb(170, 0, 26);
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* .dropdown:hover .name {
  background-color: #3e8e41;
} */
.steam__text {
  font-weight: 500;
  font-size: 1.042vw;
  text-align: center;
  color: #fff;
  padding-left: 0.52vw;
}

.sign__in {
  display: flex;
  align-items: center;
}

.signin {
  border: 0;
  background: #AA001A;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 1.042vw;
  padding: 0.5vw 1.41vw 0.5vw 1.41vw;
  margin-right: 2.24vw;
  font-family: var(--nun);

}

.signin__steam {
  display: flex;
  font-family: var(--nun);
  align-items: center;
  background: #AA001A;
  border-radius: 5px;
  border: 0;
  padding: 0.5vw 1.41vw 0.5vw 1.41vw;
  font-weight: 500;
  /* height: 3.54vw; */
}

.pc__header {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  z-index: 5;
  position: fixed;
  padding-top: 0.5vw;
  padding-left: 2.6vw;
  padding-right: 1.35vw;
  padding-bottom: 0.5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .3s ease;
}

.logo__img {
  width: 9.26vw;
}

.navbar__btn:hover {
  background: linear-gradient(235.92deg, #753ef9 14.85%, #9d75ff 87.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.purse {
  position: relative;
  display: flex;
}

.purse__btn {
  display: flex;
  border: 0;
  background: linear-gradient(83.8deg, #C62C44 15.62%, #FF0027 91.62%);
  border-radius: 10px;
  width: 4.01vw;
  height: 2.24vw;
}

.purse__count {
  font-weight: 500;
  font-size: 1.46vw;
  color: #fff;
  margin-left: 16px;
  font-family: var(--unb);
}

.name {
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  font-size: 1.146vw;
  font-family: var(--nun);
  /* padding-left: 0.68vw; */
  /* padding-right: 0.68vw; */
}

.user {
  display: flex;
  margin-left: 1.823vw;
  margin-right: 0.78vw;
  align-items: center;
}

.cart__btn {
  padding: 0.10vw 0.73vw 0.78vw 0.73vw;
  height: 2.24vw;
  background: linear-gradient(83.8deg, #C62C44 15.62%, #FF0027 91.62%);
  border-radius: 10px;
  border: 0;
  display: flex;
}

.cart__count {
  font-weight: 500;
  font-size: 1.46vw;
  color: #fff;
  padding-left: 7px;
}

@media screen and (max-width: 780px) {
  .cartCounter {
    font-size: 0.833vw;
  }

  .name {
    font-size: 12px;
  }

  .purse__count {
    font-size: 12px;
  }

  .walletImg {
    width: 1.8vw !important
  }

  .cartCont img {
    width: 3.08vw !important;
  }

  .cartCounter {
    font-size: 1.233vw;
  }

  .logo__img {
    width: 14.259999999999998vw;
  }
}

@media screen and (min-width:481px) {
  .accountHeaderImgM {
    display: none;
  }
}

@media screen and (max-width:480px) {
  .pc__header {
    display: none;
  }

  .mob__header {
    display: block;
  }

  .purse__count {
    font-size: 2.55vw;
  }

  .walletCont {
    padding-right: 5vw;
    display: none;
  }

  .walletImg {
    width: 3vw !important;
  }

  .cartCounter {
    font-size: 1.55vw;
  }

  .cartCont img {
    width: 4vw !important
  }

  .cartCont {
    margin-right: 4vw;
    margin-top: 1vw;
  }

  .pc__header {
    position: fixed;
    align-items: center;
    background-color: rgb(32, 32, 37);
  }

  .signin__steam {
    display: none;
  }

  .logo__img {
    width: 14.26vw;
  }

  .signin {
    font-size: 3vw;
    height: unset;
  }

  .purse {
    display: none;
  }

  .user {
    /* flex-direction: column; */
    align-items: center;
  }

  .name {
    /* margin-bottom: 0;
    font-size:12px; */
    display: none;
  }

  .navbar {
    padding: 0.5rem 0.5rem;
  }

  .accountHeaderImgPC {
    display: none;
  }

  .accountHeaderImgM {
    width: 49px;
  }

  .cart__btn {
    width: 9vw;
    height: 6vw;
    align-items: center;
    padding: 0;
    justify-content: center;
    margin-left: 10px;
  }

  .cart__btn img {
    padding-top: 0 !important;
    width: 3vw !important;
  }

  .cart__count {
    margin-bottom: 0;
    display: none;
  }

  .dropdown-content {
    min-width: unset;
  }

  .dropdown-content a {
    padding: 5px 5px;
  }
}
</style>