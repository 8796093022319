<template>
    <div class="cartSell" id="cartSell" style="z-index:10000000">
        <div class="cart_container">
            <div class="cart__content">
                <img src="../assets/img/close.svg" alt="" @click="closeHeader()" style="cursor: pointer; width: 3.65vw" />
                <p class="your__cart">Товары на продажу</p>
            </div>
            <SellCartItemMenu :cart_data="SELL_CART"></SellCartItemMenu>
            <hr style="width: 29.74vw" />
            <div class="cart__total pl__pr">
                <p class="cart__total__text">Итого</p>
                <p class="cart__total__text">{{ cartSum + '₸' }} </p>
            </div>
            <div class="cart__buttons pl__pr">
                <button class="cart__button" @click="openSell()">
                    Оформить заказ
                </button>
            </div>
        </div>
    </div>

    <sell-modal>

        <template #header>
            <div class="sellmodal__header text-center">
                <h1>Вы продаете</h1>
            </div>
        </template>

        <template #body>

            <div class="sellmodal__body">
                <div class="d-flex justify-content-between align-items-center" v-for="items in SELL_CART" :key="items.id">

                    <p>{{ items.full_type }}</p> <span>{{ Math.floor(items.cost) }} ₸</span>

                </div>
            </div>

        </template>

        <template #footer>

            <div class="sellmodal__footer">

                <hr>

                <div class="d-flex justify-content-between align-items-center">

                    <p>Итого</p> <span>{{ cartSum.toLocaleString() }} ₸</span>

                </div>

                <div class="form-check mt-4">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        Я согласен с <router-link @click="closeSell(), trackPage('/privacy')" to="/privacy">пользовательским
                            соглашением и
                            политикой
                            конфиденциальности</router-link>
                    </label>
                </div>

                <div class="text-center mt-4">
                    <button>Продать</button>
                </div>

            </div>

        </template>

    </sell-modal>
</template>

<script>
import SellCartItemMenu from "../views/SellCartItemMenu.vue";
import sellModal from "@/views/sellModal.vue";
import { mapGetters } from "vuex";

export default {
    data() {
        return {};
    },
    components: {
        SellCartItemMenu,
        sellModal
    },
    props: {
        sellcart_item_data: {
            type: Object,
            default() {
                return [];
            },
        },
    },
    methods: {
        openSell() {
            $("#sellmodal").modal("show");
        },
        closeHeader() {
            let sc = $("#cartSell")[0];
            sc.style.transition = "all 0.8s";
            sc.style.right = -101 + "vw";
        },
        deleteSellCart() {
            this.$emit("deleteSellCart");
        },
    },
    computed: {
        ...mapGetters(["SELL_CART"]),
        cartSum() {
            return this.SELL_CART.reduce(
                (previousValue, currentValue) =>
                    previousValue + Math.floor(currentValue.price),
                0
            );
        },
    },
};
</script>

<style scoped>
.your__cart {
    font-weight: 500;
    font-size: 1.67vw;
    color: #ffffff;
    text-align: center;
    margin-left: 5vw;
    margin-bottom: 0;
}

.test::-webkit-scrollbar {
    background: #181820;
    border-radius: 15px;
    width: 9px;
}

.test::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #2C6DF5;
}

.test::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background-color: #181820;
}

.test {
    height: 500px;
    overflow-y: scroll;
}

.cart__button {
    background: #AA001A;
    border-radius: 5px;
    padding: 0.78vw 1.09vw 0.78vw 1.09vw;
    color: #fff;
    font-weight: 400;
    font-size: 1.04vw;
    border: 0;
    margin-bottom: 1.67vw;
}

.cart__total__text {
    font-weight: 500;
    font-size: 1.46vw;
    color: #fff;
    font-family: var(--nun);
}

.cart__src {
    font-weight: 400;
    font-size: 1.04vw;
    text-decoration-line: underline;
    color: #fff;
}

.cart__total {
    margin-top: 2.08vw;
    display: flex;
    justify-content: space-between;
}

.cart__buttons {
    margin-top: 2.45vw;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    font-family: var(--nun);
}

hr {
    border: 1px solid #f7f7f7;
}

.product__price {
    margin-top: 5.63vw;
}

.product__count {
    font-weight: 400;
    font-size: 1.25vw;
    color: #f7f7f7;
    margin-right: 3vw;
    white-space: nowrap;
    font-family: var(--unb);
}

.product__text {
    font-weight: 500;
    font-size: 0.83vw;
    color: #f7f7f7;
}

.product__name {
    font-weight: 400;
    font-size: 1.25vw;
    color: #fff;
    font-family: var(--unb);
}

.product__close {
    position: absolute;
    left: 26.08vw;
    top: 0;
}

.cart__products {
    margin-top: 3.96vw;
    margin-bottom: 2.4vw;
}

.cart__product {
    background: transparent;
    border-radius: 0.26vw;
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
}

.pl__pr {
    padding-left: 2.08vw;
    padding-right: 2.08vw;
}

.cart__content {
    padding-top: 2.6vw;
    padding-left: 1.2vw;
    padding-right: 1.56vw;
    display: flex;
    align-items: center;
}

.cart__summary {
    display: flex;
    font-weight: 500;
    font-size: 1.46vw;
    color: #f7f7f7;
    background: linear-gradient(83.8deg, #c62c44 15.62%, #ff0027 91.62%);
    border-radius: 0.26vw;
    padding: 0.26vw 0.26vw 0.26vw 0.26vw;
    align-items: center;
}

.cartSell {
    height: 100vh;
    width: 33.85vw;
    display: block;
    /*height: 100%;*/
    transition: all 2.2s ease 0s;
    right: -101vw;
    top: 0px;
    position: fixed;
    background-color: white;
    z-index: 1001;
    overflow: auto;
    background: #272525;
    box-shadow: -10px 0px 15px rgba(0, 0, 0, 0.15);
}


@media screen and (max-width:780px) {
    .product__text {
        font-size: 1.729vw;
        margin-bottom: 0.5rem;
    }

    .product__desc {
        margin-left: 1vw;
        width: 7.813vw;
    }
}

@media screen and (max-width: 480px) {
    .your__cart {
        font-family: var(--unb);
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
    }

    .cartSell {
        width: 90vw;
    }

    .cart__productIMG {
        width: 23vw !important;
    }

    .product__name {
        font-size: 14px;
        margin-bottom: 0.5rem;
    }

    .product__text {
        font-size: 12px;
        margin-bottom: 0.5rem;
    }

    .product__count {
        font-size: 12px;
    }

    .product__close {
        left: unset;
        right: 10px;
    }

    .product__close img {
        width: 5vw !important;
    }

    .cart__total__text {
        font-size: 14px;
    }

    .cart__src {
        font-size: 14px;
    }

    .cart__button {
        font-size: 14px;
    }

    .cart_container {
        padding-bottom: 30px;
    }

    .test {
        height: 400px;
    }

    .cart__content img {
        width: 8vw !important;
    }

    .cart__summary {
        font-size: 14px;
        margin-bottom: 0;
    }

    .cart__summary img {
        width: 5vw !important;
    }
}
</style>