<template>
  <a name="FAQ"></a>
  <div class="faq">
    <div class="faq__bg pl__pr">
      <div class="faq__text">
        <img src="@/assets/img/question.svg" style="width: 2.08vw" alt="" />
        <p class="faq__main">Часто задаваемые вопросы</p>
      </div>
      <div class="faq__flex row">
        <div class="accordion col-12 col-lg-6" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne">
              <div class="coll__flex">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapse0" aria-expanded="true" aria-controls="collapse0" id="faqBtn0">
                    Произошла ошибка при выводе предмета, как быть?
                  </button>
                </h2>
                <div class="p" id="faqPlus0">+</div>
              </div>
            </div>

            <div id="collapse0" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body">
                Мы не можем отправить тебе предмет в следующих случаях:<br> ссылка на обмен устарела,<br> у тебя
                ограничение по использованию трейда в Steam,<br> сервера Steam в данный момент недоступны
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <div class="coll__flex">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapse1" aria-expanded="true" aria-controls="collapse1" id="faqBtn1">
                    Я не забрал предмет сразу и больше не могу принять обмен, что делать?
                  </button>
                </h2>
                <div class="p" id="faqPlus1">+</div>
              </div>
            </div>
            <div id="collapse1" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div class="card-body">
                Предметы хранятся в течение 7 дней после их получения в профиле на сайте, далее предмет будет
                автоматически продан
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <div class="coll__flex">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapse2" aria-expanded="true" aria-controls="collapse2" id="faqBtn2">
                    Пополнил счёт - деньги не пришли, почему?
                  </button>
                </h2>
                <div class="p" id="faqPlus2">+</div>
              </div>
            </div>
            <div id="collapse2" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div class="card-body">
                Иногда деньги обрабатываются платёжной системой не мгновенно. Чаще всего платёжная система хочет
                убедиться, что ты не мошенник. Необходимо подождать 15-20 минут. Если по истечению этого времени баланс
                не пополнился - обратись к нам в техподдержку.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFour">
              <div class="coll__flex">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapse3" aria-expanded="true" aria-controls="collapse3" id="faqBtn3">
                    Как выйти из своего профиля?
                  </button>
                </h2>
                <div class="p" id="faqPlus3">+</div>
              </div>
            </div>
            <div id="collapse3" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
              <div class="card-body">
                Нажми на иконку своего профиля в правом верхнем углу экрана, а далее на кнопку "Выйти". Мы будем
                скучать!
              </div>
            </div>
          </div>
        </div>
        <div class="accordion col-12 col-lg-6" id="accordion2">
          <div class="card">
            <div class="card-header" id="headingFive">
              <div class="coll__flex">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapse4" aria-expanded="true" aria-controls="collapse4" id="faqBtn4">
                    Я не нашёл вопрос, который меня интересует!
                  </button>
                </h2>
                <div class="p" id="faqPlus4">+</div>
              </div>
            </div>

            <div id="collapse4" class="collapse" aria-labelledby="headingFive" data-parent="#accordion2">
              <div class="card-body">
                Обратись в техподдержку сайта - мы ответим на любые вопросы. Ну почти на любые вопросы)
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingSix">
              <div class="coll__flex">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapse5" aria-expanded="true" aria-controls="collapse5" id="faqBtn5">
                    Что делать если выдает ошибку "Ошибка проверки ссылки"?
                  </button>
                </h2>
                <div class="p" id="faqPlus5">+</div>
              </div>
            </div>
            <div id="collapse5" class="collapse" aria-labelledby="headingSix" data-parent="#accordion2">
              <div class="card-body">
                Причину и срок снятие блокировки вы можете найти тут
                https://help.steampowered.com/wizard/HelpWhyCantITrade
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingSeven">
              <div class="coll__flex">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapse6" aria-expanded="true" aria-controls="collapse6" id="faqBtn6">
                    Не могу применять промокод!
                  </button>
                </h2>
                <div class="p" id="faqPlus6">+</div>
              </div>
            </div>
            <div id="collapse6" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion2">
              <div class="card-body">
                Некоторые промокоды недоступны на 0 уровне Steam аккаунта. При достижении требуемого уровня, указанного
                в ошибке при пополнении, тебе необходимо выйти из профиля на сайте и зайти снова
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingEight">
              <div class="coll__flex">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapse7" aria-expanded="true" aria-controls="collapse7" id="faqBtn7">
                    У меня не открываются некоторые кейсы, пишет: "В данном кейсе нет вещей".
                  </button>
                </h2>
                <div class="p" id="faqPlus7">+</div>
              </div>
            </div>
            <div id="collapse7" class="collapse" aria-labelledby="headingEight" data-parent="#accordion2">
              <div class="card-body">
                Для обеспечения честного рандома, кейсы всегда содержат в себе все предметы. Боты иногда не успевают
                закупиться всеми нужными предметами или необходимые предметы отсутствуют на торговой площадке
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    collaps(a) {
      var faqBtn = document.querySelectorAll(`#faqBtn${a}`);
      var faqPlus = document.querySelectorAll(`#faqPlus${a}`);
      if (!faqBtn[0].classList.contains("collapsed")) {
        for (let i = 0; i < faqBtn.length; i++) {
          faqPlus[i].innerHTML = "-";
        }
      } else {
        faqPlus[0].innerHTML = "+";
      }
    },
  },
  mounted() {
    for (let i = 0; i < 8; i++) {
      $(`#collapse${i}`).on('show.bs.collapse', function () {
        document.querySelector(`#faqPlus${i}`).innerHTML = "-";
      })
      $(`#collapse${i}`).on('hide.bs.collapse', function () {
        document.querySelector(`#faqPlus${i}`).innerHTML = "+";
      })
      document.querySelector(`#faqPlus${i}`).onclick = () => {
        $(`#collapse${i}`).collapse('toggle')
      }
    }
  },
};
</script>

<style scoped>
.card-body {
  font-family: var(--nun);
  background: #060810 !important;
  color: #fff;
  font-weight: 400;
  font-size: 1.25vw;
}

.coll__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-link {
  font-family: var(--nun);
  color: #fff;
  font-weight: 400;
  font-size: 1.25vw;
}

.p {
  width: 5.21vw;
  font-size: 2.34vw;
  color: #fff;
  background:
    rgba(170, 0, 26, 1);
  border-radius: 0px 5px 5px 0px;
  padding: 1.77vw 1.77vw 1.77vw 1.77vw;
  cursor: pointer;
}

.card-header {
  background:
    rgb(70 10 19);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0;
}

.card {
  border: 1px solid rgb(36, 4, 9) !important;
  margin-bottom: 1.25vw;
}

.faq__flex {
  display: flex;
}

.faq__text {
  margin-top: 9.38vw;
  margin-bottom: 2.92vw;
  display: flex;
  align-items: baseline;
}

.faq__answer {
  font-weight: 400;
  font-size: 1.25vw;
  color: #fff;
}

.faq {
  margin-top: 5vw;
}

.faq__main {
  margin-left: 1.25vw;
  font-weight: 500;
  font-size: 2.08vw;
  letter-spacing: 0.025em;
  color: #ffffff;
  text-align: left;
  font-family: var(--unb);
}

.pl__pr {
  padding-left: 5.21vw;
  padding-right: 5.21vw;
}

summary::-webkit-details-marker {
  display: none;
}

summary::-moz-list-bullet {
  list-style-type: none;
}

summary::marker {
  display: none;
}

summary {
  display: inline-block;
  padding: 0.3em 0.5em 0.3em 1.09vw;
  font-weight: 400;
  font-size: 1.25vw;
  cursor: pointer;
  color: #fff;
}

summary:before {
  content: "+";
  margin-right: 0.3em;
}

details[open]>summary:before {
  content: "–";
}

details[open]>summary {
  color: #fff;
}

summary~* {
  padding: 0 1em 0 1em;
}

summary:focus {
  outline: 0;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
}

details {
  background: rgba(44, 109, 245, 0.2);
  border: 1px solid #2c6df5;
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  margin-bottom: 1.56vw;
  outline: 0;
}

@media (max-width:780px) {
  .btn-link {
    font-size: 1.65vw;
  }

  .card-body {
    font-size: 1.65vw;
  }
}

@media screen and (max-width: 480px) {
  .p {
    height: 13vw;
    border-radius: 0;
    padding: 4.77vw 1.77vw 1.77vw 1.77vw;
    min-width: 44px;
    text-align: center;
  }

  .btn-link,
  .card-body {
    font-size: 14px;
  }

  .faq__text img {
    width: 4.06vw !important;
  }

  .faq__main {
    font-size: 20px;
  }

  summary,
  .faq__answer {
    font-size: 2.5vw;
  }
}
</style>