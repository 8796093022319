<template>
    <section class="shop">
        <div class="main__shop text-center">
            <h1>Магазин товаров</h1>
        </div>
        <div class="container-fluid cont__pd">
            <div class="filters__container">
                <div class="row">
                    <div class="col-md-12 col-12">
                        <div class="d-flex justify-content-between align-items-center catalog__topfilters">

                            <input type="text" class="catalog__search" placeholder="Поиск по товарам" v-model.trim="search">
                            <div class="d-flex align-items-center">
                                <div class="filtcontm">
                                    <!-- <span class="filters__spans">Редкость</span> -->
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle dropdown__filters" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            Редкость
                                            <img src="@/assets/img/catalogArrowM.png" class="catalog__ArrowImg" alt="">
                                        </button>
                                        <div class="dropdown-menu catalog__dropmenu " aria-labelledby="dropdownMenuButton">
                                            <a href="#" v-for="rarity in rarityList" :key="rarity.id"
                                                :class="['dropdown-item', 'catalog__dropitem', { 'catalog__dropitemBorder': rarity.id != 5 }, { 'active': rarity.id == this.rareSelect }]"
                                                @click="this.rareSelect = rarity.id">
                                                {{ rarity.rare }}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="filtcontm">
                                    <!-- <span class="filters__spans">Состояние</span> -->
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle dropdown__filters" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            Состояние
                                            <img src="@/assets/img/catalogArrowM.png" class="catalog__ArrowImg" alt="">
                                        </button>
                                        <div class="dropdown-menu catalog__dropmenu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item catalog__dropitem" v-for="condition in conditionList"
                                                :key="condition.id" @click="this.conditionSelect = condition.id"
                                                :class="['dropdown-item', 'catalog__dropitem', { 'catalog__dropitemBorder': condition.id != 5 }, { 'active': condition.id == this.conditionSelect }]">
                                                {{ condition.cond }}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="prices__container">
                                    <span class="filters__spans">Цена</span>
                                    <div class="filter__prices">
                                        <input type="number" class="price__min" placeholder="от 0.00"
                                            v-model.trim="lowprice">
                                        <span class="ml-2 mr-2">&mdash;</span>
                                        <input type="number" class="price__max" placeholder="до 0.00"
                                            v-model.trim="highprice">
                                    </div>
                                </div>

                                <button class="btn filter__button">
                                    Применить
                                </button>
                            </div>



                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="container-fluid cont__pd mt-4">
            <div class="preshop-container">
                <div class="shop-container">
                    <div class="row filtersAndCatalog">
                        <div class="col-12 col-md-12 catalog">
                            <!-- <div class="row"> -->
                            <!-- <div class="col-2" > -->

                            <div class="item" v-for="item in pageWeaponsList" :key="item.id" @click="openModal(item.id)">


                                <div class="item__top">
                                    <div class="d-flex flex-column">
                                        <div
                                            :class="['item__rare', { 'shirp': item.rarity == 'Ширпотреб' }, { 'blue': item.rarity == 'Армейское Качество' }, { 'tayna': item.rarity == 'Тайное' }, { 'prom': item.rarity == 'Промышленное' }, { 'zap': item.rarity == 'Запрещённое' }, { 'zas': item.rarity == 'Засекреченное' }]">
                                            {{ item.rarity == "Армейское Качество" ? "Армейское" : item.rarity }}
                                        </div>

                                    </div>

                                </div>
                                <img :src="item.image" class="item__img" alt="">
                                <div class="item__bottomcont">
                                    <div class="item__bottom">
                                        <span class="item__name">
                                            {{ item.gun }} {{ item.name }}

                                        </span>
                                        <div class="item__qual">
                                            {{ item.state == 'Factory New' ? 'FN' : item.state == 'Minimal Wear' ? 'MW' :
                                                item.state == 'Field-Tested' ? 'FT' : item.state == 'Well-Worn' ? 'WW' :
                                                    item.state ==
                                                        'Battle-Scarred' ? 'BS' : item.state }}
                                        </div>
                                    </div>
                                    <span class="item__price">
                                        {{ Math.floor(item.cost) }} ₸
                                    </span>
                                </div>
                            </div>

                            <!-- </div> -->
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="container-fluid cont__pd mt-4">
            <div class="pagination__cont">
                <button class="pagination__prev" @click="prevPage">
                    Назад
                </button>
                <div class="pagination__numbers">
                    {{ this.pageNumber }} / {{ this.MaxPages }}
                </div>
                <button class="pagination__next" @click="nextPage">
                    Вперед
                </button>
            </div>
        </div>
    </section>
    <product-modal :product="modal.modalProductName"></product-modal>
</template>

<script>
import { useMeta } from 'vue-meta'
import ProductModal from '@/components/ProductModal.vue';
export default {
    setup() {
        useMeta(
            {
                title: 'Магазин',
                description: [
                    { content: 'cs-market, кейсы, маркетплейс, магазин' }
                ]
            }
        )
    },
    emits: ['product'],
    data() {
        return {
            product: {
                productName: {},
            },
            pokemons: [],
            modal: {
                modalProductName: {},
            },
            sort: 'По умолчанию',
            rarityList: [
                { id: 0, rare: 'Любое' },
                { id: 1, rare: 'Армейское качество' },
                { id: 2, rare: 'Запрещённое' },
                { id: 3, rare: 'Засекреченное' },
                { id: 4, rare: 'Тайное' },
                { id: 5, rare: 'Ширпотреб' },
            ],
            conditionList: [
                { id: 0, cond: 'Любое', short: 'All' },
                { id: 1, cond: 'Factory New', short: 'Factory New', float: 0 },
                { id: 2, cond: 'Minimal Wear', short: 'Minimal Wear', float: 0.2 },
                { id: 3, cond: 'Field-Tested', short: 'Field-Tested', float: 0.4 },
                { id: 4, cond: 'Well-Worn', short: 'Well-Worn', float: 0.6 },
                { id: 5, cond: 'Battle-Scarred', short: 'Battle-Scarred', float: 0.8 },
            ],
            pageNumber: 1,
            weapons: [],
            search: '',
            lowprice: '',
            highprice: '',
            rareSelect: 0,
            conditionSelect: 0,
        }
    },

    methods: {
        test(id) {
            console.log(id)
        },
        getData() {
            const path = 'https://cs-market.kg/api/items';
            axios.get(path)
                .then((res) => {
                    this.weapons = res.data;
                    console.log(res.data[0]);
                })
                .catch((error) => {
                    // eslint-выключение следующей строки
                    console.error(error);
                });
        },

        openModal(id) {
            // console.log(this.weapons.filter(item => item.id == id)[0].name)
            this.modal.modalProductName = this.weapons.filter(item => item.id == id)[0];
            $('#ProductModal').modal('show')
        },
        reloadCatalog() {
            this.search = '';
            this.lowprice = '';
            this.highprice = ''
            this.rareSelect = 0;
            this.conditionSelect = 0;
            this.sort = 'По умолчанию';
        },
        addRarity(id) {
            this.rareSelect.push(id)
        },
        removeRarity(id) {
            this.rareSelect.splice(this.rareSelect.indexOf(id), 1)
            console.log(this.rareSelect)
        },
        nextPage() {
            if (this.pageNumber < this.filteredWeapons.length / 21) {
                this.pageNumber = this.pageNumber + 1;
                // console.log(this.pageNumber)
            }
        },
        prevPage() {
            if (this.pageNumber > 1) {
                this.pageNumber = this.pageNumber - 1;
                // console.log(this.pageNumber)
            }
        },
    },
    computed: {
        filteredWeapons() {
            let searched = this.weapons.filter((product) => {
                if (this.lowprice === '') {
                    return product.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
                }
                else {
                    return product.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && product.cost > this.lowprice;
                }
            });
            if (this.highprice !== '') {
                searched = searched.filter((product) => {
                    return product.cost <= Number(this.highprice)
                })
            };
            if (this.rareSelect != 0) {
                searched = searched.filter(product => {
                    return product.rarity.toLowerCase() == (this.rarityList[this.rareSelect].rare.toLowerCase())
                })
            }
            if (this.conditionSelect != 0) {
                searched = searched.filter(product => {
                    return product.state == (this.conditionList[this.conditionSelect].short)
                })
            }
            if (this.sort === 'По цене (Min)') {
                searched.sort((a, b) => {
                    return a.cost - b.cost
                })
            }
            if (this.sort === 'По цене (Max)') {
                searched.sort((a, b) => {
                    return b.cost - a.cost
                })
            }


            // console.log(searched[0])

            this.pageNumber = 1;
            return searched

        },
        pageWeaponsList() {
            if (this.filteredWeapons.length > 0) {
                let pageWeapons = [];
                let ind = 0;
                if (this.pageNumber > 1) {
                    ind = (this.pageNumber - 1) * 21
                }
                for (let i = ind; i <= ind + 20 && i < this.filteredWeapons.length; i++) {
                    pageWeapons.push(this.filteredWeapons[i])
                }
                console.log(pageWeapons)
                return pageWeapons;
            }
        },
        MaxPages() {
            return Math.ceil(this.filteredWeapons.length / 21);
        }
    },
    mounted() {
        this.getData();
    },
    components: { ProductModal }
}
</script>

<style scoped>
.main__shop h1 {
    color: #fff;
    font-weight: 500;
    font-size: 40px;
    line-height: 32px;
    font-family: var(--unb);
}

.main__shop {
    margin-bottom: 40px;
}

.item__qual {
    font-family: var(--nun);
}

.pagination__cont {
    display: flex;
    justify-content: space-between;
    background-color: #272525;
    align-items: center;
    border-radius: 5px;
}

.pagination__prev {
    background-color: #272525;
    border: 0;
    color: #FFFFFF;
    font-size: 1.250vw;
    padding: 20px;
    font-family: var(--nun);
    border-radius: 5px;
}

.pagination__next {
    background-color: #272525;
    border: 0;
    color: #FFFFFF;
    font-size: 1.250vw;
    padding: 20px;
    font-family: var(--nun);
    border-radius: 5px;

}

.pagination__numbers {
    color: #FFFFFF;
    font-size: 1.250vw;
    font-family: var(--nun);
}


.cont__pd {
    padding-left: 5.208vw;
    padding-right: 5.208vw;
}

.filters__container {
    padding: 17px 24px;
    background: #272525;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
}

.shop {
    padding-top: 160px;
    background: no-repeat url(@/assets/img/SolderBack.png);
    background-position: top left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

.preshop-container {
    background: #272525;
    border-radius: 5px;
    padding: 16px;
}

.shop-container {
    background-color: #060810;
    /* width: 96vw; */
    padding: 16px;
    border-radius: 5px;
    /* margin-left: 30px; */
    /* margin-right: 30px; */
}


.catalog__reloadImg {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.catalog__lineImg {
    margin-left: 0.563vw;
    margin-right: 0.563vw;
}

.dropdown__catalog {
    color: white;
    font-weight: 500;
    font-size: 1.250vw;
    background-color: unset;
}

.dropdown__filters {
    /* margin-top: 15px; */
    color: white;
    font-weight: 400;
    font-size: 1.042vw;
    font-family: var(--nun);

}

.dropdown__catalog::after {
    display: none;
}

.dropdown__filters::after {
    display: none;
}

.dropdown__filters:focus {
    box-shadow: 0 0 0 0.2rem #3F7AF5;
}

.catalog__ArrowImg {
    width: 10px;
    margin-left: 16px;
}

.dropdown__catalog:focus {
    box-shadow: 0 0 0 0.2rem #3F7AF5;
}

.catalog__dropmenu {
    background-color: #2B2E37;
    box-shadow: 0px 8px 16px 0px rgba(63, 122, 245, 0.2);
    color: white;
    padding: 0;
}

.catalog__dropitem {
    color: white;
    font-size: 20px;
}

.catalog__dropitemBorder {
    border-bottom: 1px solid white;
}

.catalog__dropmenu .active {
    background-color: rgba(44, 109, 245, 0.2);
    color: #2C6DF5;
    /* border: 1px solid #753ef9; */
}

.catalog__dropitem:hover {
    color: white;
    font-size: 20px;
    background-color: #3F7AF5;
    cursor: pointer;
}

.catalog__search {
    background-color: #171717;
    background: url(@/assets/img/loopCatalogM.png) no-repeat 10px center;
    background-size: 1.250vw;
    color: white;
    padding-left: 45px;
    width: 20vw;
    height: 2.865vw;
    font-size: 24px;
    font-weight: 500;
    border-color: rgba(54, 53, 60, 0);
    border-radius: 5px;
    font-family: var(--nun);
}

.catalog__search::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 24px;
    font-weight: 500;
}


/* FILTERS */
.filtersAndCatalog {
    /* margin-top: 30px; */
    /* padding-bottom: 2.6vw;     */
}

.filters {
    color: white;
    font-weight: 600;
    font-size: 1.250vw;
}

.filter__prices {
    color: white;
    display: flex;
    align-items: center;
}

.prices__container {
    display: flex;
    align-items: center;
    margin-right: 35px;
}

.price__min {
    width: 7.917vw;
    height: 38px;
    font-weight: 500;
    font-size: 1.458vw;
    background: #060810;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    background: url(@/assets/img/priceTenge.png) no-repeat right 5px center #141721;
    padding-right: 30px;
    color: white;
    background-size: 1.563vw;
    font-family: var(--nun);

}

.price__max {
    width: 7.917vw;
    height: 38px;
    font-weight: 500;
    font-size: 1.458vw;
    background: #060810;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    background: url(@/assets/img/priceTenge.png) no-repeat right 5px center #141721;
    padding-right: 30px;
    color: white;
    background-size: 1.563vw;
    font-family: var(--nun)
}

.filters__spans {
    color: white;
    font-weight: 400;
    font-size: 1.250vw;
    margin-right: 16px;
    font-family: var(--nun);
}



/* RARITY */

.rarity {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.rarity__btn {
    color: white;
    padding: 0 0;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 24px;

}

.rarity__btn:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

.rarity .card {
    background-color: unset;
    padding: 0;
    border: 0;
}

.cheks__label {
    display: flex;
    align-items: center;
}

.customCheckbox {
    float: left;
    position: relative;
    width: 24px;
    height: 24px;
    background-color: unset;
    border-radius: 2px;
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    /*margin-right: 5px;*/
    margin-right: 0.26vw;
    overflow: hidden;
}

.customCheckbox.customCheckboxChecked {
    background: #753ef9;
    border: 1px solid white;
}

.customCheckbox input {
    opacity: 0;
    cursor: pointer;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.customCheckbox span {
    display: none;
    text-align: center;
    line-height: 20px;
    font-size: 90%;
    color: #222;
}

.customCheckbox.customCheckboxChecked span {
    display: block;
}

.cheks__span {
    font-weight: 500;
    font-size: 24px;
    margin-left: 15px;
}

.filter__button {
    color: white;
    background-color: #AA001A;
    border-radius: 5px;
    font-family: var(--nun);
}

/* CATALOG */
.catalog {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.catalog::-webkit-scrollbar-track {
    background-color: #181820;
}

.catalog::-webkit-scrollbar {
    width: 10px;
}

.catalog::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 20px #FF0027;
}

.item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #272525;
    border-radius: 5px;
    height: fit-content;
    box-shadow: 2px 4px 10px #00000040;
    height: auto;
}

.item__top {
    /* position: relative; */
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
    padding-right: 10px;
    padding-left: 8px;

}

.item__rare {
    color: #fff;
    font-family: var(--nun);
    text-transform: uppercase;
    top: 0;
}

a:hover {
    text-decoration: none !important;
}

.shirp {
    background: #2D4AF2;
    ;
    border-radius: 5px;
    padding: 4px 8px;
}

.blue {
    background: #2D4AF2;
    ;
    border-radius: 5px;
    padding: 4px 8px;
}

.prom {
    background: #448CD9;
    border-radius: 5px;
    padding: 4px 8px;
}

.zas {
    background: #D32CE6;
    border-radius: 5px;
    padding: 4px 8px;
}

.zap {
    background: #8847FF;
    border-radius: 5px;
    padding: 4px 8px;
}

.shirp {
    background: #618393;
    border-radius: 5px;
    padding: 4px 8px;
}

.tayna {
    background: #EB4338;
    border-radius: 5px;
    padding: 4px 8px;
}

.item__name {
    /* position: absolute; */
    bottom: 0;
    margin-left: 5px;
    font-size: 1.250vw;
    font-weight: 500;
    color: white;
    padding-right: 10px;
    font-family: var(--unb);
    line-height: 1.5vw;
}

.item__img {
    width: 100%;
    height: 9vw;
}

.item__bottom {
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    font-size: 1.250vw;
    color: rgba(255, 255, 255, 0.3);
    font-weight: 400;
    margin-top: 5px;
}

.item__price {
    font-weight: 400;
    font-size: 1.250vw;
    padding: 0 10px;
    color: #AA001A;
    font-family: var(--unb);
    margin-top: 10px;
}

.filtcontm {
    margin-right: 43px;
}

.item__more {
    background: rgba(255, 255, 255, 0.3);
    color: white;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    line-height: 1.719vw;
    display: none;
    margin-top: 10px;
    /* flex-grow: 1; */
    font-family: 'Roboto', sans-serif;
    /* padding-top: 5px; */
    padding-bottom: 3px;
}

.item__bottomcont {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item:hover .item__more {
    display: block;
}

.item:hover .item__price {
    color: #fff;
}

.item:hover {
    /* red grad */

    background: radial-gradient(82.21% 62.79% at 50% 50%, #180105 0%, #AA001A 100%)
}

@media (max-width: 1024px) {
    .catalog {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:780px) {}

@media screen and (max-width:480px) {
    .main__shop h1 {
        font-size: 20px;
    }

    .preshop-container {
        padding: 0;
    }

    .cont__pd {
        padding-left: 2.208vw;
        padding-right: 2.208vw;
    }

    .catalog {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 20px;
        grid-column-gap: 5px;
        overflow-y: hidden;
    }

    .reloadsort {
        width: 100%;
    }

    .dropdown__catalog {
        font-size: 4.25vw;
    }

    .prices__container {
        width: 100%;
    }

    .price__min {
        width: 35vw;
        font-size: 5.458vw;
        background-size: 15px;
    }

    .price__max {
        width: 35vw;
        font-size: 5.458vw;
        background-size: 15px;
    }

    .filtcontm {
        width: 100%;
    }

    .dropdown__filters {
        font-size: 6.25vw;
    }

    .filter__button {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .item__img {
        height: 38vw;
    }

    .catalog__topfilters {
        flex-wrap: wrap;
    }

    .catalog__search {
        width: 80vw;
        height: 15vw;
    }

    .shop {
        padding-top: 70px;
    }

    .catalog__search::placeholder {
        color: white;
        font-size: 24px !important;
        font-weight: 500;
    }

    .price__min::placeholder {
        font-size: 24px !important;
    }

    .price__max::placeholder {
        font-size: 24px !important;
    }

    .catalog__search {
        background-size: 20px;
    }

    .dropdown__filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .prices__container {
        margin-top: 20px;
        flex-direction: column;
        align-items: normal
    }

    .filters__spans {
        font-size: 5.25vw;
    }

    .item__name {
        font-size: 3.25vw;
        line-height: 3.5vw;
    }

    .item__bottom {
        font-size: 3.25vw;
    }

    .item__price {
        font-size: 3.25vw;
    }

    .pagination__prev {
        font-size: 3.25vw;
    }

    .pagination__numbers {
        font-size: 3.25vw;
    }

    .pagination__next {
        font-size: 3.25vw;
    }
}

@media (max-width: 390px) {
    .item {
        max-width: 160px;
    }

    .item__rare {
        font-size: 14px;
    }

    .item__more {
        padding-top: 10px;
    }
}

@media (max-width: 320px) {
    .item {
        max-width: 125px;
    }

    .item__rare {
        font-size: 12px;
        text-transform: none;
    }
}
</style>