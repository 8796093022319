<template>
  <div class="modal fade" id="moneyModal" tabindex="-1" aria-labelledby="moneyModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-size">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header">header</slot>
        </div>
        <div class="modal-body">
          <slot name="body">body</slot>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import IMask from "imask";
export default {
  mounted() {

  }
}
</script>

<style scoped>
.modal-header {
  border-bottom: 0;
}

.modal-content {
  background: #272525;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #AA001A;
  border-radius: 0 !important;
}

.modal-body {
  padding: 0 !important;
}

@media (max-width: 1440px) {

  .modal-content {
    width: 130% !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 651px;
    margin: 1.75rem auto;
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 100% !important;
  }
}
</style>