<template>
    <div class="modal fade" id="sellmodal" tabindex="-1" aria-labelledby="sell" aria-hidden="true"
        style="z-index: 1000000000;">
        <div class="modal-dialog modal-dialog-centered modal-size">
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="header">header</slot>
                </div>
                <div class="modal-body">
                    <slot name="body">body</slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">footer</slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.modal-content {
    background: #141721;
    border: 1px solid #AA001A;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #AA001A;
    border-radius: 0 !important;
}

.modal-body {
    padding: 0 !important;
}
</style>