<template>
  <div class="navbar">
    <a href="/" :class="['navbar__btn', { 'active': currentPage == '/' }]">
      Главная
      <div :class="['underline', { 'hovered': currentPage == '/' }]"></div>
    </a>
    <router-link to="/sale" @click="trackPage('/sale')" :class="['navbar__btn', { 'active': currentPage == '/sale' }]">
      Продажа
      <div :class="['underline', { 'hovered': currentPage == '/sale' }]"></div>
    </router-link>
    <router-link to="/shop" @click="trackPage('/shop')" :class="['navbar__btn', { 'active': currentPage == '/shop' }]">
      Магазин
      <div :class="['underline', { 'hovered': currentPage == '/shop' }]"></div>
    </router-link>
    <a href="/#FAQ" :class="['navbar__btn']">FAQ</a>

  </div>
</template>

<script>
export default {
  computed: {
    currentPage() {
      return this.$route.path;
    }
  }
}
</script>

<style scoped>
.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  border-bottom: 2px solid #AA001A;
  transition: all .3s ease;
}

.hovered {
  width: 100%;
}

.navbar__btn {
  margin-right: 2.24vw;
  font-size: 1.250vw;
  font-weight: 300;
  color: #fff;
  font-family: var(--nun);
  position: relative;
}

.active {
  font-weight: 700;
}

@media (max-width: 780px) {
  .navbar__btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .navbar__btn {
    font-size: 3vw;
  }
}
</style>