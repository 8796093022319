<template>
    <div class="test" v-if="cart_data.length !== 0">
        <div class="cart__products pl__pr" v-for="(item, i) in cart_data" :key="item" :sellcart_item_data="item"
            @deleteSellCart="deleteSellCart(i)">
            <div class="cart__product">
                <img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + item.icon_url" alt=""
                    style="width: 8.96vw" class="cart__productIMG" />
                <div class="product__desc">
                    <p class="product__name"> {{ item.name }} </p>
                </div>
                <div class="product__price">
                    <p class="product__count">{{ Math.floor(item.price) }} ₸</p>
                </div>
                <div class="product__close">
                    <img src="../assets/img/close.svg" alt="" style="width: 2.6vw; cursor: pointer"
                        @click="deleteSellCart(i)" />
                </div>
            </div>
        </div>
    </div>
    <div class="pure" v-else>
        <p style="
        color: #fff;
        text-align: center;
        font-size: 1.56vw;
        margin-top: 2.6vw;
      ">
            Корзина пуста
        </p>
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: {
        cart_data: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    methods: {
        ...mapActions(['DeleteFromSellCart']),
        deleteSellCart(i) {
            this.deleteSellCart(i)
        }
    },
    computed: {

    }
};
</script>

<style  scoped>
.cart__products hr {
    background: #FFFFFF;
    opacity: 0.3;
}

.product__desc {
    margin-left: 1vw;
}
</style>