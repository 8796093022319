<template>
    <div class="modal fade" id="regModal" tabindex="-1" aria-labelledby="regModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header text-center d-block pt-5">
                    <h5 class="modal-title" id="exampleModalLabel">Регистрация</h5>
                </div>
                <div class="modal-body">

                    <div class="text-center">
                        <p>Внимание! Если Вы зарегистрируетесь через почту, функция продажи предметов будет недоступна.</p>
                        <p>Зарегистрируйтесь через Steam для подключения своего инвентаря и доступа ко всем функциям!</p>
                    </div>

                    <form action="https://steamcommunity.com/openid/login" method="post" class="mt-4 text-center">
                        <input type="hidden" name="openid.identity"
                            value="http://specs.openid.net/auth/2.0/identifier_select" />
                        <input type="hidden" name="openid.claimed_id"
                            value="http://specs.openid.net/auth/2.0/identifier_select" />
                        <input type="hidden" name="openid.ns" value="http://specs.openid.net/auth/2.0" />
                        <input type="hidden" name="openid.mode" value="checkid_setup" />
                        <input type="hidden" name="openid.realm" value="https://megacase.kz/" />
                        <input type="hidden" name="openid.return_to" value="https://megacase.kz/error" />
                        <button class="steamLog"><img src="../assets/img/steamlogin.svg" alt="" class="img-fluid mr-2">
                            Войти
                            через
                            Steam</button>
                    </form>
                    <div class="form-group mt-5">
                        <label for="emailInp">Ваш e-mail</label>
                        <input v-model="username" type="email" class="form-control" id="emailInp"
                            aria-describedby="emailHelp" placeholder="E-mail">

                    </div>
                    <div class="form-group">
                        <label for="password">Пароль</label>
                        <input v-model="password" type="password" class="form-control" id="password"
                            aria-describedby="password" placeholder="Введите пароль">

                    </div>
                    <div class="form-group">
                        <label for="passwordRepeat">Повторите пароль</label>
                        <input v-model="repeatPassword" type="password" class="form-control" id="passwordRepeat"
                            aria-describedby="password" placeholder="Повторите пароль">

                    </div>
                </div>
                <div class="modal-footer text-center d-block">
                    <button class="loginBtn mb-3" @click="reg()">Зарегистрироваться</button>
                    <span>Есть аккаунт? <a @click="openLog()">Войти</a></span>


                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            repeatPassword: '',
        }
    },
    methods: {
        openLog() {
            $("#regModal").modal("hide");
            $("#loginModal").modal("show");
        },
        reg() {
            let btn = document.querySelector('.loginBtn')
            if (this.username.length > 0 && this.password.length > 0 && this.repeatPassword.length > 0) {
                if (this.password === this.repeatPassword) {
                    btn.innerHTML = 'Ожидайте'
                    const path = "https://cs-market.kg/api/register";
                    axios
                        .post(path, { email: this.username, password: this.password, site_name: 'megacase' })
                        .then((res) => {
                            if (res.status == 200) {
                                $("#regModal").modal("hide");
                                $("#loginModal").modal("show");
                                btn.innerHTML = 'Зарегистрироваться'
                                console.log(res);
                            }
                            else {

                                alert("ошибка")
                                btn.innerHTML = 'Повторите попытку'
                                console.log(res)
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            btn.innerHTML = 'Повторите попытку'

                        });
                }
                else {
                    alert('Пароли не совпадают')
                }
            }
            else {
                alert("Вы не заполнили обязательные поля")
            }
        }
    }
}

</script>
<style scoped>
.modal-body p {
    font-family: var(--nun);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
}

.steamLog {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    background: transparent;
    padding: 16px 32px;

    font-family: var(--nun);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
}

.modal-footer a {
    font-family: var(--nun);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #AA001A;
}

.modal-footer span {
    font-family: var(--nun);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
}

.loginBtn {
    width: 100%;
    background: #AA001A;
    border-radius: 5px;
    border: 0;
    padding: 16px 0;

    font-family: var(--nun);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
}

.modal-footer {
    padding: 0 50px 32px 50px;
}

.modal-body {
    padding: 32px 50px;
}

.modal-body input::placeholder {
    font-family: var(--nun);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.3);

}

.modal-body input {
    background: transparent;
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    font-family: var(--nun);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
}

.modal-body label {
    font-family: var(--nun);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
}

.modal-header,
.modal-body,
.modal-footer {
    border-top: 0;
    border-bottom: 0;
}

.modal-content {
    background: #272525;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header h5 {
    font-family: var(--unb);
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
}

.modal-dialog {
    max-width: 611px;
}
</style>