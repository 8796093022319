<template>
  <div class="d-flex justify-content-center">
    <img src="@/assets/img/kek.svg" class="trap2" alt="">
  </div>
  <section class="footer container-fluid">
    <div class="footer__counts row">
      <div class="footer__numbersCont col-6 col-md-4">
        <span class="footer__underNum"> Открытых кейсов </span>
        <span class="footer__number">
          {{ currentlyOpened }}
        </span>
      </div>
      <div class="footer__numbersCont col-6 col-md-4">
        <span class="footer__underNum"> Всего пользователей </span>
        <span class="footer__number">
          {{ usersAll }}
        </span>
      </div>
      <div class="footer__numbersCont kek col-6 col-md-4">
        <span class="footer__underNum"> Завершено сделок </span>
        <span class="footer__number">
          {{ usersOnline }}
        </span>
      </div>
    </div>
    <div class="notmb">
      <hr />

      <div class="payImg__cont" v-if="paymentImage">
        <img class="payImg" src="@/assets/img/payImg1.png" alt="">
      </div>

      <div class="footer_navigation">
        <img src="@/assets/img/newlogo.svg" style="width: 10.94vw" alt="" />
        <the-navbar></the-navbar>
        <a href="/Privacy" class="privacy">Политика конфиденциальности</a>
        <a href="/Terms" class="privacy">Пользовательское соглашение</a>
      </div>
      <hr />
      <div class="copyright">
        <p class="copyright__text">Товарищество с ограниченной ответственностью "DGroup2020", 201140036434, Казахстан,
          город Астана, район Есиль, улица Сауран, дом 42, н.п. 3, почтовый индекс 010000
        </p>

      </div>
      <div class="copyright">
        <p class="copyright__text">
          Почта поддержки : <a class="copyright__text" href="mailto:support@megacase.kz">support@megacase.kz</a>
        </p>
      </div>
    </div>
    <div class="contacts">
      <span>Товарищество с ограниченной ответственностью "DGroup2020", 201140036434, Казахстан,
        город Астана, район Есиль, улица Сауран, дом 42, н.п. 3, почтовый индекс 010000</span><br>
      <span>Почта поддержки: <a href="mailto:support@megacase.kz">support@megacase.kz</a></span>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    currentlyOpened() {
      let curDate = new Date();
      return (
        500428 +
        Math.floor(
          (Date.parse(curDate) - Date.parse("2022-04-03T13:51:50.417-07:00")) /
          60 /
          60 /
          24
        )
      );
    },
    usersAll() {
      let curDate = new Date();
      return (
        20673 +
        Math.floor(
          (Date.parse(curDate) - Date.parse("2022-04-03T13:51:50.417-07:00")) /
          60 /
          60 /
          24
        )
      );
    },
    usersOnline() {
      let curDate = new Date();
      return Math.floor(7340 + Math.random() * (2000 - 1) + 1);
    },
    paymentImage() {
      return this.$route.path == '/' ? true : false
    }
  },
};
</script>

<style scoped>
.contacts span,
.contacts a {
  font-size: 14px;
  color: #fff;
  font-family: var(--nun);
  margin-bottom: 10px;
}

.contacts {
  display: none;
  margin-bottom: 50px;
}

.trap2 {
  display: none;
}

.payImg {
  width: 35.68vw;
}

.payImg__cont {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.copyright__text {
  font-weight: 400;
  font-size: 1.04vw;
  color: rgba(255, 255, 255, 0.3);
  font-family: var(--nun);
}

.copyright {
  display: flex;
  justify-content: center;
  padding-left: 5.21vw;
  padding-right: 5.21vw;
}

.privacy {
  color: #fff;
  font-weight: 400;
  font-size: 0.94vw;
  font-family: var(--nun);
}

.footer_navigation {
  display: flex;
  padding-left: 5.21vw;
  padding-right: 5.21vw;
  padding-top: 2.6vw;
  padding-bottom: 2.6vw;
  justify-content: space-between;
  align-items: center;
}

.footer__counts {
  padding-top: 2.21vw;
  padding-bottom: 5.21vw;
  padding-left: 15.63vw;
  padding-right: 15.63vw;
  display: flex;
  justify-content: space-between;
}

hr {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.footer__ska {
  padding-top: 7vw;
}

.footer__navl {
  padding-left: 4vw;
  width: 5.21vw;
}

.footer__bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: 0;
}

.footer__padding {
  padding-top: 2.5vw;
  padding-left: 3.65vw;
}

.tt {
  position: absolute;
  bottom: 0;
  right: 0;
}

.heart__flex {
  padding-left: 15vw;
  display: flex;
  padding-bottom: 2.5vw;
}

.footer__flex {
  display: flex;
}

.footer {
  margin-top: 5.2vw;
  background-color: #000;
}

.ak47 {
  justify-content: center;
}

.ak47 img {
  width: 8.854vw;
}

/* numbers */

.footer__numbersFirst {
  position: relative;
  margin-left: 28.3vw;
  width: 70.21vw;
  background: #1f1f23;
  border-radius: 50px 0px 0px 50px;
  color: white;
  /* align-items: center;
  justify-content: space-around; */
}

.footer__numbersCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer__numbersCont span {
  text-align: center;
}

.footer__number {
  color: #fff;
  font-size: 3.65vw;
  font-weight: 900;
  font-family: var(--unb);
}

.footer__underNum {
  color: rgba(170, 0, 26, 1);
  font-size: 1.25vw;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--nun);
}

.footer__logo {
  width: 20.31vw;
}

/* NAV */
.footer__nav {
  margin-top: 5.208vw;
}

.navbar {
  justify-content: center;
}

/* LINKS */
.footer__links {
  margin-top: 2.031vw;
}

.footer__links a {
  color: #ffffff;
  font-style: italic;
  text-decoration: underline;
  font-size: 1.25vw;
}

/* ADRESS */
.adress {
  margin-top: 3.125vw;
  color: #ffffff;
  text-align: center;
  font-size: 1.042vw;
}

@media screen and (max-width: 480px) {
  .trap2 {
    display: block;
  }

  .contacts {
    display: block;
  }

  .notmb {
    display: none;
  }

  .kek {
    margin-top: 23px;
    margin-left: 25%;
  }

  .footer__counts {
    padding-left: 1.6300000000000008vw;
    padding-right: 1.6300000000000008vw;
  }

  .copyright__text {
    font-size: 2vw;
  }

  .footer_navigation img {
    width: 20vw !important;
  }

  .privacy {
    font-size: 1.72vw;
    margin-right: 2vw;
  }

  .footer__navl {
    padding-left: 0;
    width: 5.21vw;
  }

  .ak47 img,
  .footer__logo {
    width: 20vw;
  }

  .footer__number {
    font-size: 5.3vw;
  }

  .footer__underNum {
    font-size: 2.875vw;
  }

  .footer__links a {
    font-size: 2.25vw;
  }

  .adress {
    font-size: 2vw;
  }

  .footer__numbersFirst {
    background: unset;
    margin-left: 15vw;
  }

  .footer__flex {
    align-items: flex-start;
  }
}
</style>