<template>
    <div class="selected__body d-flex">

        <div class="selected__item" v-for="(sell, i) in sell_data" :key="sell.i" :sell_item_data="sell"
            @deleteSellCart="deleteSellCart(i)">

            <div class="item__header d-none">
                <p
                    :class="['item__rare', { 'blue': sell.rarity == 'Армейское качество' }, { 'tayna': sell.rarity == 'Тайное' }, { 'prom': sell.rarity == 'Промышленное' }, { 'zap': sell.rarity == 'Запрещённое' }, { 'zas': sell.rarity == 'Засекреченное' }]">
                    {{ sell.rarity == "Армейское качество" ? "Армейское" : sell.rarity }}</p>
            </div>
            <div class="item__img1">
                <img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + sell.icon_url" alt=""
                    style="max-width: 184px" />
            </div>
            <div class="item__footer">
                <div class="d-flex justify-content-between">
                    <p> {{ sell.name }}</p>
                    <small>{{ sell.quality }}</small>
                </div>

                <span>{{ Math.floor(sell.price) }} ₸</span>
            </div>
            <div class="item__more" @click="deleteSellCart(i)">
                Удалить
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: {
        sell_data: {
            type: Array,
            default() {
                return [];
            }
        },
    },
    data() {
        return {


        }
    },
    methods: {
        ...mapActions(['DeleteFromSellCart']),
        deleteSellCart(i) {
            this.deleteSellCart(i)
        }
    },
}
</script>

<style scoped>
.selected__item:hover .item__more {
    display: block;
}

.selected__item {
    position: relative;
}

.item__more {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
    color: white;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    line-height: 1.719vw;
    display: none;
    margin-top: 10px;
    /* flex-grow: 1; */
    font-family: 'Roboto', sans-serif;
    /* padding-top: 5px; */
    padding-bottom: 3px;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 10000;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.blue {
    background: #2D4AF2 !important;
    ;
    border-radius: 5px;
    padding: 4px 8px;
}

.prom {
    background: #448CD9 !important;
    border-radius: 5px;
    padding: 4px 8px;
}

.zas {
    background: #D32CE6 !important;
    border-radius: 5px;
    padding: 4px 8px;
}

.zap {
    background: #8847FF !important;
    border-radius: 5px;
    padding: 4px 8px;
}

.shirp {
    background: #618393 !important;
    border-radius: 5px;
    padding: 4px 8px;
}

.tayna {
    background: #EB4338 !important;
    border-radius: 5px;
    padding: 4px 8px;
}

.item__footer span {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    font-family: var(--unb);
    color: #AA001A;
}

.item__footer small {
    font-family: var(--nun);
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: rgba(255, 255, 255, 0.3);
}

.item__footer p {
    font-family: var(--unb);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
}

.item__footer {
    padding: 0px 16px 10px 16px;
}

.item__img1 {
    padding: 27px 37px 35px 37px;
}

.item__header p {
    float: right;
    color: #fff;
    font-family: var(--nun);
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;

    background: #D32CE6;
    border-radius: 5px;
    padding: 4px 8px;

}

.item__header {
    padding: 8px;
}

.selected__item {
    background: radial-gradient(82.39% 82.39% at 50% 50%, #1E1D21 0%, #3C3B3F 100%);
    border-radius: 5px;
    margin-left: 20px;
    width: 258px;
    min-width: 258px;
}

.selected__body::-webkit-scrollbar {
    width: 0;
}

.selected__body {
    padding: 16px 16px 0px 0px;
    margin: 24px 18px;
    background: #060810;
    border-radius: 5px;
    max-width: 1667px;
    position: relative;

    overflow-x: auto;
}
</style>