import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cases',
    name: 'cases',
    component: () => import('../components/Cases.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../components/Shop.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../components/Account.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../components/CartPage.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsOfUse.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/SteamError.vue')
  },
  {
    path: '/payerror',
    name: 'payerror',
    component: () => import('../views/PaymentErrorPage.vue')
  },
  {
    path: '/instruction',
    name: 'instruction',
    component: () => import('../views/InstructionPage.vue')
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import('../components/ProductPage.vue')
  },
  {
    path: '/sale',
    name: 'sale',
    component: () => import('../components/SaleItem.vue')
  },
  {
    path: '/mobile_success',
    name: 'mobile_success',
    component: () => import('../components/mobile_Succes.vue')
  },
  {
    path: '/mobile_failure',
    name: 'mobile_failure',
    component: () => import('../components/mobile_Failure.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../components/Succes.vue')
  },
  {
    path: '/failure',
    name: 'failure',
    component: () => import('../components/Failure.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
