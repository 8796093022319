<template>
    <section class="shop" v-if="IsLogged == 'true'">
        <div class="sellcart__bt">
            <button class="sellcartbtn" @click="openSellCart()">Товары на продажу ({{ sellCartSum }})</button>
        </div>
        <div>
            <div class="main__shop text-center">
                <h1>Продажа товаров</h1>
            </div>
            <div class="container-fluid cont__pd">
                <div class="filters__container">
                    <div class="row">
                        <div class="col-md-12 col-12">
                            <div class="d-flex justify-content-between align-items-end catalog__topfilters">

                                <input type="text" class="catalog__search" placeholder="Поиск по товарам"
                                    v-model.trim="search">

                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="container-fluid cont__pd mt-4">
                <div class="preshop-container">
                    <div class="d-flex justify-content-between align-items-center updatesect pb-3">
                        <h1>Выберите предметы на продажу</h1>
                        <button @click="postUserBag()" class="d-flex align-items-center"> <img
                                src="@/assets/img/refresh.svg" class="mr-2" alt=""> Обновить
                            инвентарь</button>
                    </div>
                    <div class="shop-container">
                        <div class="row filtersAndCatalog">
                            <div class="col-12 col-md-12 catalog">
                                <!-- <div class="row"> -->
                                <!-- <div class="col-2" > -->
                                <div class="item" v-for="item in filteredBag" :key="item.id" v-show="item.marketable == 1">




                                    <div class="item__top">
                                        <div class="d-flex flex-column">
                                            <!-- <div
                                                :class="['item__rare', { 'blue': item.rarity == 'Армейское качество' }, { 'tayna': item.rarity == 'Тайное' }, { 'prom': item.rarity == 'Промышленное' }, { 'zap': item.rarity == 'Запрещённое' }, { 'zas': item.rarity == 'Засекреченное' }]">
                                                {{ item.rarity == "Армейское качество" ? "Армейское" : item.rarity }}
                                            </div> -->

                                        </div>

                                    </div>
                                    <img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + item.icon_url"
                                        class="img-fluid" alt="">
                                    <div class="item__bottomcont">
                                        <span class="tradelock" v-if="item.tradable == 0">🔒 trade lock</span>
                                        <div class="item__bottom">

                                            <span class="item__name">
                                                {{ item.name }}
                                            </span>
                                            <div class="item__qual">
                                                {{
                                                    item.descriptions[0].value == 'Exterior: Field-Tested' ? 'FT' :
                                                    item.descriptions[0].value == 'Exterior: Minimal Wear' ? 'MW' :
                                                        item.descriptions[0].value == 'Exterior: Well-Worn' ? 'WW' :
                                                            item.descriptions[0].value == 'Exterior: Battle-Scarred' ? 'BS' :
                                                                item.descriptions[0].value == 'Exterior: Battle-Scarred' ? 'BS' :
                                                                    item.descriptions[0].value == 'Exterior: Factory New' ? 'FN' :
                                                                        item.descriptions[0].value
                                                }}
                                            </div>
                                        </div>



                                    </div>

                                    <div class="item__more" @click="openModal(item.classid)">
                                        Выбрать
                                    </div>
                                </div>

                                <!-- </div> -->
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="container-fluid cont__pd mt-4">
                <div class="pagination__cont">
                    <button class="pagination__prev" @click="prevPage">
                        Назад
                    </button>
                    <div class="pagination__numbers">
                        {{ this.pageNumber }} / {{ this.MaxPages }}
                    </div>
                    <button class="pagination__next" @click="nextPage">
                        Вперед
                    </button>
                </div>
            </div>

            <section class="selected__items mt-4" v-if="SELL_CART.length > 0">
                <div class="selected">
                    <button style="background:transparent; border: 0; position: absolute; top:48%; z-index: 100;"
                        onclick="document.querySelector('.selected__body').scrollBy(-100, 0)">
                        <img src="@/assets/img/scrolll.svg" alt="">
                    </button>

                    <button style="background:transparent; border: 0; position: absolute; top: 48%; right: 0; z-index: 100"
                        onclick="document.querySelector('.selected__body').scrollBy(100, 0)">
                        <img src="@/assets/img/scrollr.svg" alt="">
                    </button>

                    <div class="selected__header d-flex justify-content-between align-items-center">
                        <h1>Предметы на продажу</h1>
                        <div class="header-right d-flex">
                            <p class="m-0 selected__count">Предметов: {{ sellCartSum }}</p>
                            <p class="m-0">Сумма: {{ cartPrice.toLocaleString() }} ₸</p>
                        </div>
                    </div>

                    <selected-items-view :sell_data="SELL_CART"></selected-items-view>

                    <div class="text-right sell__btn">
                        <button @click="openSell()">Подтвердить продажу</button>
                    </div>
                </div>

                <sell-modal>

                    <template #header>
                        <div class="sellmodal__header text-center">
                            <h1>Вы продаете</h1>
                        </div>
                    </template>

                    <template #body>

                        <div class="sellmodal__body">
                            <div class="d-flex justify-content-between align-items-center" v-for="items in SELL_CART"
                                :key="items.id">

                                <p>{{ items.name }}</p> <span>{{ Math.floor(items.price) }} ₸</span>

                            </div>
                        </div>

                    </template>

                    <template #footer>

                        <div class="sellmodal__footer">

                            <hr>

                            <div class="d-flex justify-content-between align-items-center">

                                <p>Итого</p> <span>{{ cartPrice.toLocaleString() }} ₸</span>

                            </div>

                            <div class="form-check mt-4">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Я согласен с <router-link @click="closeSell(), trackPage('/privacy')"
                                        to="/privacy">пользовательским
                                        соглашением и
                                        политикой
                                        конфиденциальности</router-link>
                                </label>
                            </div>

                            <div class="text-center mt-4">
                                <button>Продать</button>
                            </div>

                        </div>

                    </template>

                </sell-modal>
            </section>
        </div>
        <SellProductModal :product="modal.modalProductName"></SellProductModal>
        <sellCartMenu></sellCartMenu>
    </section>
    <div class="text-center notSteam" v-else>
        <div class="notSteam__body">
            <div class="text-center">
                <img src="@/assets/img/steamLoginError.png" class="img-fluid" alt="">

                <h1>Для продажи товаров необходима<br> авторизация через Steam</h1>

                <p>Пожалуйста, авторизуйтесь и попробуйте еще раз!</p>

                <form action="https://steamcommunity.com/openid/login" method="post">
                    <input type="hidden" name="openid.identity"
                        value="http://specs.openid.net/auth/2.0/identifier_select" />
                    <input type="hidden" name="openid.claimed_id"
                        value="http://specs.openid.net/auth/2.0/identifier_select" />
                    <input type="hidden" name="openid.ns" value="http://specs.openid.net/auth/2.0" />
                    <input type="hidden" name="openid.mode" value="checkid_setup" />
                    <input type="hidden" name="openid.realm" value="https://megacase.kz/" />
                    <input type="hidden" name="openid.return_to" value="https://megacase.kz/error" />
                    <button>
                        Войти через Steam
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from 'vuex';
import SelectedItemsView from '@/views/SelectedItemsView.vue'
import SellProductModal from '@/components/SellProductModal.vue';
import sellModal from "@/views/sellModal.vue";
import sellCartMenu from "@/views/sellCartMenu.vue";
import jwt_decode from "jwt-decode";
import { useMeta } from 'vue-meta'
export default {
    setup() {
        useMeta(
            {
                title: 'Продажа',
                description: [
                    { content: 'cs-market, кейсы, маркетплейс, магазин, продажа' }
                ]
            }
        )
    },
    props: {
        sell_item_data: {
            type: Object,
            default() {
                return [];
            },
        },
    },
    data() {
        return {

            product: {
                productName: {},
            },
            pokemons: [],
            modal: {
                modalProductName: {},
            },
            sort: 'По умолчанию',
            rarityList: [
                { id: 0, rare: 'Любое' },
                { id: 1, rare: 'Армейское качество' },
                { id: 2, rare: 'Запрещённое' },
                { id: 3, rare: 'Засекреченное' },
                { id: 4, rare: 'Тайное' },
                { id: 5, rare: 'Экстра' },
            ],
            conditionList: [
                { id: 0, cond: 'Любое', short: 'All' },
                { id: 1, cond: 'Factory New', short: 'FN', float: 0 },
                { id: 2, cond: 'Minimal Wear', short: 'MW', float: 0.2 },
                { id: 3, cond: 'Field-Tested', short: 'FT', float: 0.4 },
                { id: 4, cond: 'Well-Worn', short: 'WW', float: 0.6 },
                { id: 5, cond: 'Battle-Scarred', short: 'BS', float: 0.8 },
            ],
            pageNumber: 1,
            weapons: [],
            inventoryUser: [],
            search: '',
            lowprice: '',
            highprice: '',
            rareSelect: 0,
            conditionSelect: 0,
        }
    },

    methods: {
        postUserBag() {
            let token = localStorage.getItem('userName')
            const path = "https://cs-market.kg/api/load_inventory/"
            axios
                .post(path, { jwt_token: token })
                .then((res) => {
                    if (res.data.succes == true) {
                        console.log(res)
                        this.getInventory()
                    }
                    else {
                        console.log(res)
                    }
                })
        },
        openSellCart() {
            let sc = $("#cartSell")[0];
            sc.style.transition = "all 0.8s";
            sc.style.display = "block";
            sc.style.right = 0;
            sc.style.top = 0;
        },
        closeSell() {
            $("#sellmodal").modal("hide");
        },
        openSell() {
            $("#sellmodal").modal("show");
        },
        ...mapActions([
            'addToSellCart'
        ]),
        addToSellCartBtn(i) {
            if (this.IsLogged) {
                this.addToSellCart(i);
            }
            else {
                $("#exampleModal").modal("show");
            }
        },
        getInventory() {
            var bag = localStorage.getItem('userBag')

            this.inventoryUser = bag
        },
        getData() {
            const path = 'https://cs-market.kg/api/shop';
            axios.get(path)
                .then((res) => {
                    this.weapons = res.data;
                    console.log(res.data[0]);
                })
                .catch((error) => {
                    // eslint-выключение следующей строки
                    console.error(error);
                });
        },
        openProduct(id) {
            this.product.productName = this.weapons.filter(item => item._id['$oid'] == id)[0];
        },
        openModal(id) {
            // console.log(this.weapons.filter(item => item.id == id)[0].name)
            this.modal.modalProductName = this.inventoryUser.descriptions.filter(item => item.classid == id)[0];
            $('#SellProductModal').modal('show')
        },
        reloadCatalog() {
            this.search = '';
            this.lowprice = '';
            this.highprice = ''
            this.rareSelect = 0;
            this.conditionSelect = 0;
            this.sort = 'По умолчанию';
        },
        addRarity(id) {
            this.rareSelect.push(id)
        },
        removeRarity(id) {
            this.rareSelect.splice(this.rareSelect.indexOf(id), 1)
            console.log(this.rareSelect)
        },
        nextPage() {
            if (this.pageNumber < this.filteredBag.length / 21) {
                this.pageNumber = this.pageNumber + 1;
                // console.log(this.pageNumber)
            }
        },
        prevPage() {
            if (this.pageNumber > 1) {
                this.pageNumber = this.pageNumber - 1;
                // console.log(this.pageNumber)
            }
        },
        deleteSellCart() {
            this.$emit("deleteSellCart");
        },
    },
    computed: {
        cartPrice() {
            return this.SELL_CART.reduce(
                (previousValue, currentValue) =>
                    previousValue + Math.floor(currentValue.price),
                0
            );
        },
        ...mapGetters(["SELL_CART", "IsLogged"]),
        sellCartSum() {
            return this.SELL_CART.length
        },
        filteredBag() {
            return this.inventoryUser.descriptions?.filter((item) =>
                (String(item.name).toLowerCase().includes(String(this.search).toLowerCase()))
            )

            this.pageNumber = 1;

        },

        pageWeaponsList() {
            if (this.filteredBag.length > 0) {
                let pageWeapons = [];
                let ind = 0;
                if (this.pageNumber > 1) {
                    ind = (this.pageNumber - 1) * 21
                }
                for (let i = ind; i <= ind + 20 && i < this.filteredBag.length; i++) {
                    pageWeapons.push(this.filteredBag[i])
                }
                console.log(pageWeapons)
                return pageWeapons;
            }
        },
        MaxPages() {
            return Math.ceil(this.filteredBag?.length / 21);
        }
    },
    mounted() {
        this.getInventory();


        if (this.IsLogged) {
            var token = localStorage.getItem('userName')
            var decoded = jwt_decode(token);
            axios({
                method: 'get',
                url: `https://cs-market.kg/api/get_user_openid/${decoded.open_id}`,
            })
                .then((response) => {
                    if (response.data.jwt_token) {
                        localStorage.setItem("userName", response.data.jwt_token);
                        localStorage.setItem("userBag", response.data.inventory)
                        this.inventoryUser = response.data.inventory
                        console.log(this.inventoryUser)
                    }
                    else {
                        return
                    }
                })
                .catch((error) => {

                    console.error(error);
                })
        }
    },
    components: { SellProductModal, SelectedItemsView, sellModal, sellCartMenu }
}
</script>

<style scoped>
.notSteam__body button {
    background: #AA001A;
    border-radius: 5px;
    border: 0;
    width: 100%;
    padding: 16px 0;
    margin-top: 37px;

    font-family: var(--nun);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
}

.notSteam__body p {
    font-family: var(--nun);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    margin-top: 38px;
}

.notSteam__body h1 {
    font-family: var(--unb);
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    margin-top: 50px;
}

.notSteam {
    padding-top: 200px;
    display: flex;
    justify-content: center;
}

.notSteam__body {
    background: #141721;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 47px 40px 56px 40px;
}

.tradelock {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: var(--nun);
    color: #fff;
    text-transform: uppercase;
    margin-left: 16px;
    width: 70%;
    margin-top: 10px;


    background: rgba(239, 134, 65, 0.56);
    border: 1px solid #EF8641;
    border-radius: 5px;
    padding: 4px 8px;
}

.updatesect h1 {
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    font-family: var(--unb);
    color: #fff;
}

.updatesect button {
    background: #AA001A;
    border-radius: 5px;
    padding: 10px 15px;
    border: 0;

    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    font-family: var(--nun);
    color: #fff;


}

.sellcart__bt {
    display: none;
    padding: 0 24px;
    position: fixed;
    width: 100%;
    bottom: 2%;
    z-index: 1000;

}

.sellcartbtn {
    background: #AA001A;
    border-radius: 5px;
    padding: 15px 0;
    width: 100%;
    border: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    font-family: var(--nun);
    color: #fff;
}

.auth {
    text-align: center;
    font-size: 6vw;
    color: #fff;
    padding-top: 9vw;
}

.sellmodal__footer button {
    background: #AA001A;
    border-radius: 5px;
    padding: 16px 0;
    width: 100%;
    border: 0;

    font-family: var(--nun);
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
}

.form-check-input:checked {
    background-color: red;
}

.form-check-input {
    background-color: green;
    width: 30px;
    height: 30px;
    transform: none !important;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);

}

.sellmodal__footer label {
    color: #fff;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-left: 32px;
}

.sellmodal__footer a {
    color: #fff;
    text-decoration: underline;
}

.sellmodal__footer hr {
    opacity: 0.3;
}

.sellmodal__footer {
    padding: 0 50px 40px 50px;
}

.sellmodal__body span,
.sellmodal__footer span {
    font-family: var(-unb);
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
}

.sellmodal__body p,
.sellmodal__footer p {
    font-family: var(--nun);
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
}

.sellmodal__body {
    padding: 50px 51px 23px 50px;
    margin-bottom: 35px;
    margin-top: 20px;
}

.sellmodal__header h1 {
    font-family: var(--unb);
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
}

.sellmodal__header {
    padding-top: 40px;
}

.sell__btn button {
    background: #AA001A;
    border-radius: 5px;
    padding: 16px 148px;
    border: 0;

    color: #fff;
    font-family: var(--nun);
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
}

.sell__btn {
    padding-right: 27px;
    padding-bottom: 24px;
}

.item__footer span {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    font-family: var(--unb);
    color: #AA001A;
}

.item__footer small {
    font-family: var(--nun);
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: rgba(255, 255, 255, 0.3);
}

.item__footer p {
    font-family: var(--unb);
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
}

.item__footer {
    padding: 0px 16px 10px 16px;
}

.item__img1 {
    padding: 27px 37px 35px 37px;
}

.item__header p {
    float: right;
    color: #fff;
    font-family: var(--nun);
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;

    background: #D32CE6;
    border-radius: 5px;
    padding: 4px 8px;

}

.item__header {
    padding: 8px;
}

.selected__item {
    background: radial-gradient(82.39% 82.39% at 50% 50%, #1E1D21 0%, #3C3B3F 100%);
    border-radius: 5px;
    margin-left: 20px;
}

.selected__body::-webkit-scrollbar {
    width: 0;
}

.selected__body {
    padding: 16px 16px 16px 0px;
    margin: 24px 18px;
    background: #060810;
    border-radius: 5px;
    max-width: 1667px;
    position: relative;

    overflow-x: auto;
}

.selected__count {
    margin-right: 40px !important;
}

.selected__header h1 {
    font-family: var(--unb);
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #fff;
}

.selected__header p {
    font-family: var(--unb);
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
}

.selected__header {
    padding: 30px 36px;
}

.selected {
    background: #272525;
    border-radius: 5px;
    margin-left: 5.208vw;
    margin-right: 5.208vw;

    position: relative;
}

.main__shop h1 {
    color: #fff;
    font-weight: 500;
    font-size: 40px;
    line-height: 32px;
    font-family: var(--unb);
}

.main__shop {
    margin-bottom: 40px;
}

.item__qual {
    font-family: var(--nun);
}

.pagination__cont {
    display: flex;
    justify-content: space-between;
    background-color: #272525;
    align-items: center;
}

.pagination__prev {
    background-color: #272525;
    border: 0;
    color: #FFFFFF;
    font-size: 1.250vw;
    padding: 20px;
    font-family: var(--nun);
}

.pagination__next {
    background-color: #272525;
    border: 0;
    color: #FFFFFF;
    font-size: 1.250vw;
    padding: 20px;
    font-family: var(--nun);
}

.pagination__numbers {
    color: #FFFFFF;
    font-size: 1.250vw;
    font-family: var(--nun);
}


.cont__pd {
    padding-left: 5.208vw;
    padding-right: 5.208vw;
}

.filters__container {
    padding: 7px 7px;
    background: #272525;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
}

.shop {
    padding-top: 160px;
    background: no-repeat url(@/assets/img/SolderBack.png);
    background-position: top left;
}

.preshop-container {
    background: #272525;
    border-radius: 5px;
    padding: 16px;
}

.shop-container {
    background-color: #060810;
    /* width: 96vw; */
    padding: 16px;
    border-radius: 5px;
    /* margin-left: 30px; */
    /* margin-right: 30px; */
}


.catalog__reloadImg {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.catalog__lineImg {
    margin-left: 0.563vw;
    margin-right: 0.563vw;
}

.dropdown__catalog {
    color: white;
    font-weight: 500;
    font-size: 1.250vw;
    background-color: unset;
}

.dropdown__filters {
    /* margin-top: 15px; */
    color: white;
    font-weight: 400;
    font-size: 1.042vw;
    font-family: var(--nun);

}

.dropdown__catalog::after {
    display: none;
}

.dropdown__filters::after {
    display: none;
}

.dropdown__filters:focus {
    box-shadow: 0 0 0 0.2rem #3F7AF5;
}

.catalog__ArrowImg {
    width: 10px;
    margin-left: 16px;
}

.dropdown__catalog:focus {
    box-shadow: 0 0 0 0.2rem #3F7AF5;
}

.catalog__dropmenu {
    background-color: #2B2E37;
    box-shadow: 0px 8px 16px 0px rgba(63, 122, 245, 0.2);
    color: white;
    padding: 0;
}

.catalog__dropitem {
    color: white;
    font-size: 20px;
}

.catalog__dropitemBorder {
    border-bottom: 1px solid white;
}

.catalog__dropmenu .active {
    background-color: rgba(44, 109, 245, 0.2);
    color: #2C6DF5;
    /* border: 1px solid #753ef9; */
}

.catalog__dropitem:hover {
    color: white;
    font-size: 20px;
    background-color: #3F7AF5;
    cursor: pointer;
}

.catalog__search {
    background-color: #272525;
    background: #272525;
    background-size: 1.250vw;
    color: white;
    padding-left: 45px;
    width: 100%;
    height: 4.86vw;
    font-size: 24px;
    font-weight: 500;
    border-color: rgba(54, 53, 60, 0);
    border-radius: 5px;
    font-family: var(--nun);
}

.catalog__search::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 24px;
    font-weight: 500;
}


/* FILTERS */
.filtersAndCatalog {
    /* margin-top: 30px; */
    /* padding-bottom: 2.6vw;     */
}

.filters {
    color: white;
    font-weight: 600;
    font-size: 1.250vw;
}

.filter__prices {
    color: white;
    display: flex;
    align-items: center;
}

.prices__container {
    display: flex;
    align-items: center;
}

.price__min {
    width: 7.917vw;
    height: 38px;
    font-weight: 500;
    font-size: 1.458vw;
    background-color: #171717;
    border-color: rgba(54, 53, 60, 0);
    border-radius: 5px;
    background: url(@/assets/img/priceTenge.png) no-repeat right 5px center #141721;
    padding-right: 30px;
    color: white;
    background-size: 1.563vw;
    font-family: var(--nun);

}

.price__max {
    width: 7.917vw;
    height: 38px;
    font-weight: 500;
    font-size: 1.458vw;
    background-color: #171717;
    border-color: rgba(54, 53, 60, 0);
    border-radius: 5px;
    background: url(@/assets/img/priceTenge.png) no-repeat right 5px center #141721;
    padding-right: 30px;
    color: white;
    background-size: 1.563vw;
    font-family: var(--nun)
}

.filters__spans {
    color: white;
    font-weight: 400;
    font-size: 1.250vw;
    margin-right: 16px;
    font-family: var(--nun);
}



/* RARITY */

.rarity {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.rarity__btn {
    color: white;
    padding: 0 0;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 24px;

}

.rarity__btn:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

.rarity .card {
    background-color: unset;
    padding: 0;
    border: 0;
}

.cheks__label {
    display: flex;
    align-items: center;
}

.customCheckbox {
    float: left;
    position: relative;
    width: 24px;
    height: 24px;
    background-color: unset;
    border-radius: 2px;
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    /*margin-right: 5px;*/
    margin-right: 0.26vw;
    overflow: hidden;
}

.customCheckbox.customCheckboxChecked {
    background: #753ef9;
    border: 1px solid white;
}

.customCheckbox input {
    opacity: 0;
    cursor: pointer;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.customCheckbox span {
    display: none;
    text-align: center;
    line-height: 20px;
    font-size: 90%;
    color: #222;
}

.customCheckbox.customCheckboxChecked span {
    display: block;
}

.cheks__span {
    font-weight: 500;
    font-size: 24px;
    margin-left: 15px;
}

.filter__button {
    color: white;
    background-color: #3F7AF5;
    border-radius: 5px;
    font-family: var(--nun);
}

/* CATALOG */
.catalog {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.catalog::-webkit-scrollbar-track {
    background-color: #181820;
}

.catalog::-webkit-scrollbar {
    width: 10px;
}

.catalog::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 20px #FF0027;
}

.item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #272525;
    border-radius: 5px;
    height: fit-content;
    box-shadow: 2px 4px 10px #00000040;
    height: auto;
    position: relative;
    padding-bottom: 56px;
}

.item__top {
    /* position: relative; */
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
    padding-right: 10px;
    padding-left: 8px;

}

.item__rare {
    color: #fff;
    font-family: var(--nun);
    text-transform: uppercase;
    top: 0;
}

a:hover {
    text-decoration: none !important;
}

.blue {
    background: #2D4AF2;
    ;
    border-radius: 5px;
    padding: 4px 8px;
}

.prom {
    background: #448CD9;
    border-radius: 5px;
    padding: 4px 8px;
}

.zas {
    background: #D32CE6;
    border-radius: 5px;
    padding: 4px 8px;
}

.zap {
    background: #8847FF;
    border-radius: 5px;
    padding: 4px 8px;
}

.shirp {
    background: #618393;
    border-radius: 5px;
    padding: 4px 8px;
}

.tayna {
    background: #EB4338;
    border-radius: 5px;
    padding: 4px 8px;
}

.item__name {
    /* position: absolute; */
    bottom: 0;
    margin-left: 5px;
    font-size: 1.250vw;
    font-weight: 500;
    color: white;
    padding-right: 10px;
    font-family: var(--unb);
    line-height: 1.5vw;
}

.item__img {
    width: 100%;
    height: 9vw;
}

.item__bottom {
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    font-size: 1.250vw;
    color: rgba(255, 255, 255, 0.3);
    font-weight: 400;
    margin-top: 5px;
}

.item__price {
    font-weight: 400;
    font-size: 1.250vw;
    padding: 0 10px;
    color: #2C6DF5;
    font-family: var(--unb);
    margin-top: 10px;
}

.item__more {
    background: rgba(255, 255, 255, 0.3);
    color: white;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    line-height: 1.719vw;
    display: none;
    margin-top: 10px;
    /* flex-grow: 1; */
    font-family: 'Roboto', sans-serif;
    /* padding-top: 5px; */
    padding-bottom: 3px;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 10000;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.item__bottomcont {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.item:hover .item__more {
    display: block;
}

.item:hover .item__price {
    display: none;
}

.item:hover {
    background: radial-gradient(82.21% 62.79% at 50% 50%, #180105 0%, #AA001A 100%)
}

@media (max-width: 1024px) {
    .catalog {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:780px) {


    .selected__header h1,
    .selected__header p {
        font-size: 18px;
        margin: 0;
    }

    .updatesect h1 {
        font-size: 18px;
    }

    .updatesect button {
        font-size: 14px;
    }

    .item__name {
        font-size: 14px;
        line-height: 18px;
    }

    .sell__btn {
        padding: 0 16px 16px 16px;
    }

    .sell__btn button {
        width: 100%;
    }

    .item__more {
        padding: 10px;
    }

}

@media screen and (max-width:480px) {


    .updatesect {
        padding: 10px !important;
    }

    .updatesect button {
        font-size: 12px;
        line-height: 15px;
    }

    .updatesect h1 {
        font-size: 12px;
        line-height: 15px;
    }

    .main__shop {
        margin-top: 20px;
    }

    .product__desc {
        width: 18.8vw;
    }

    .sellcart__bt {
        display: block;
    }

    .main__shop h1 {
        font-size: 20px;
    }

    .selected {
        display: none;
    }

    .preshop-container {
        padding: 0;
    }

    .cont__pd {
        padding-left: 2.208vw;
        padding-right: 2.208vw;
    }

    .catalog {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 20px;
        grid-column-gap: 5px;
        overflow-y: hidden;
    }

    .reloadsort {
        width: 100%;
    }

    .dropdown__catalog {
        font-size: 4.25vw;
    }

    .prices__container {
        width: 100%;
    }

    .price__min {
        width: 35vw;
        font-size: 5.458vw;
        background-size: 15px;
    }

    .price__max {
        width: 35vw;
        font-size: 5.458vw;
        background-size: 15px;
    }

    .filtcontm {
        width: 100%;
    }

    .dropdown__filters {
        font-size: 6.25vw;
    }

    .filter__button {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .item__img {
        height: 38vw;
    }

    .catalog__topfilters {
        flex-wrap: wrap;
    }

    .catalog__search {
        width: 80vw;
        height: 15vw;
    }

    .shop {
        padding-top: 70px;
    }

    .catalog__search::placeholder {
        color: white;
        font-size: 24px !important;
        font-weight: 500;
    }

    .price__min::placeholder {
        font-size: 24px !important;
    }

    .price__max::placeholder {
        font-size: 24px !important;
    }

    .catalog__search {
        background-size: 20px;
    }

    .dropdown__filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .prices__container {
        margin-top: 20px;
        flex-direction: column;
        align-items: normal
    }

    .filters__spans {
        font-size: 5.25vw;
    }

    .item__name {
        font-size: 3.25vw;
        line-height: 3.5vw;
    }

    .item__bottom {
        font-size: 3.25vw;
    }

    .item__price {
        font-size: 3.25vw;
    }

    .pagination__prev {
        font-size: 3.25vw;
    }

    .pagination__numbers {
        font-size: 3.25vw;
    }

    .pagination__next {
        font-size: 3.25vw;
    }

    .notSteam {
        padding: 100px 20px;
    }

    .notSteam__body {
        padding: 20px;
    }

    .notSteam__body h1 {
        font-size: 18px;
        line-height: 20px;
    }

    .notSteam__body p {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 390px) {
    .updatesect {
        display: block !important;
    }

    .updatesect button {
        width: 100%;

        padding: 10px 15px 10px 0px;

    }

    .updatesect h1 {
        display: none;
    }

    .item {
        max-width: 160px;
    }

    .item__rare {
        font-size: 14px;
    }

    .item__more {
        padding-top: 10px;
    }
}

@media (max-width: 320px) {
    .item {
        max-width: 125px;
    }

    .item__rare {
        font-size: 12px;
        text-transform: none;
    }
}
</style>