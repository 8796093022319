<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Mega Games` : `Mega Games` }}</template>
  </metainfo>
  <the-header></the-header>
  <router-view />
  <the-footer></the-footer>

  <ops-modal v-if="IsLogged">
    <template #header>
      <div class="modal__header">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/opswallet.png" alt="" style="width: 5.73vw" />
        </div>
      </div>
    </template>
    <template #body>
      <div class="modal__body">
        <p class="modal__textOps">Упс... Что-то не так</p>
        <p class="modal__text">
          Произошла ошибка. Для её устранения вам<br />
          необходимо пополнить свой баланс
        </p>
      </div>
    </template>
    <template #footer>
      <div class="modal__btn">
        <button class="modal__button">
          <a class="modal__href" @click="openTopUp()">Пополнить баланс</a>
        </button>
      </div>
    </template>
  </ops-modal>

  <ops-modal v-else>
    <template #header>
      <div class="modal__header">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/opslogin.png" alt="" style="width: 7.81vw" />
        </div>
      </div>
    </template>
    <template #body>
      <div class="modal__body">
        <p class="modal__text">Упс... Что-то не так</p>
        <p class="modal__text">Произошла ошибка. Для того, чтобы продолжить вам<br>
          необходимо авторизоваться</p>
      </div>
    </template>
    <template #footer>
      <div class="modal__btn">
        <button class="modal__button">
          <a class="modal__href" @click="openSignIn()">Войти</a>
        </button>
      </div>
    </template>
  </ops-modal>

  <payment-error>
    <template #header>
      <div class="modal__header">
        <img src="@/assets/img/opswallet.png" alt="" style="width: 6.25vw" />
      </div>
    </template>
    <template #body>
      <div class="modal__body">
        <p class="modal__text">Упс...</p>
        <p class="modal__text">Попробуйте другую платежную систему</p>
      </div>
    </template>
    <template #footer>
      <div class="modal__btn">
        <button class="modal__button" @click="closeError()">Понятно</button>
      </div>
    </template>
  </payment-error>
</template>
<script>
$(document).on("hidden.bs.modal", function (event) {
  if ($(".modal:visible").length) {
    $("body").addClass("modal-open");
  }
});
import { useMeta } from 'vue-meta'
export default {
  setup() {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'ru', amp: true }
    })
  },
  data() {
    return {
      isIn: true,
    }
  },
  methods: {
    openLog() {
      $("#Reg").modal("hide");
      $("#LogReg").modal("show");
    },
    openReg() {
      $("#LogReg").modal("hide");
      $("#Reg").modal("show");
    },
    closeError() {
      $("#PaymentError").modal("hide");
    },
    openTopUp() {
      $("#exampleModal").modal("hide");
      $("#Money").modal("show");
    },
    openSignIn() {
      $("#exampleModal").modal("hide");
      $("#LogReg").modal("show");
    },
  },
};
</script>
<style scoped>
.reg__btn {
  display: flex;
  justify-content: center;
}

.not__acc {
  display: flex;
  justify-content: center;
}

.haveacc__btn {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2.71vw;
}

.haveacc__btn2 {
  margin-top: 1.98vw;
  display: flex;
  justify-content: center;
  margin-bottom: 2.71vw;
}

.reg__button {
  font-family: "Roboto";
  display: flex;
  font-weight: 400;
  font-size: 1.04vw;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  background: #2c6df5;
  border-radius: 5px;
  align-items: center;
  color: #fff;
  padding: 0.83vw 8.23vw 0.83vw 8.23vw;
}

.haveacc__button {
  font-family: var(--nun);
  display: flex;
  font-weight: 400;
  font-size: 1.04vw;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  align-items: center;
  color: #fff;
  padding: 0.83vw 1.67vw 0.83vw 1.67vw;
}

.haveacc__href {
  cursor: pointer;
  font-weight: 400;
  font-size: 1.04vw;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #2c6df5;
  margin-left: 0.5vw;
  font-family: var(--nun);
}

.haveacc__main {
  font-weight: 400;
  font-size: 1.04vw;
  letter-spacing: 0.03em;
  color: #fff;
  text-align: center;
  font-family: var(--nun);
}

::placeholder {
  font-weight: 500;
  font-size: 1.25vw;
  color: rgba(247, 247, 247, 0.3);
}

.modal__btn {
  text-align: center;
}

.modal__inputs {
  display: flex;
  justify-content: center;
}

.modal__input {
  background: #141721;
  box-shadow: 0px 0px 4px #2c6df5;
  border: 1px solid #246bff;
  box-sizing: border-box;
  width: 26.61vw;
  height: 3.13vw !important;
  border: 0;
  outline: 0;
  color: #fff;
  padding-left: 1.15vw;
  font-size: 1.042vw;
  margin-bottom: 1.67vw;
  font-family: var(--nun);
}

.modal__label {
  font-weight: 500;
  font-size: 1.04vw;
  color: #fff;
  font-family: var(--nun);
}

.modal__signin {
  font-family: "Roboto";
  border: 0;
  background: #2c6df5;
  border-radius: 5px;
  padding: 0.83vw 11.99vw 0.83vw 11.99vw;
  font-weight: 400;
  font-size: 1.04vw;
  color: #fff;
}

.logreg__main {
  font-family: var(--unb);
  text-align: center;
  font-weight: 900;
  font-size: 1.25vw;
  letter-spacing: 0.025em;
  color: #fff;
  padding-top: 1.93vw;
}

.modal__href {
  color: #fff;
  font-family: var(--nun);
}

.modal__href:hover {
  text-decoration: none;
}

.modal__button {
  background: #AA001A;
  border-radius: 5px;
  padding: 0.83vw 8.75vw 0.83vw 8.75vw;
  border: 0;
  font-weight: 400;
  font-size: 1.04vw;
  color: #fff;
  text-align: center;
  margin-bottom: 1.2vw;
}

.modal__btn {
  padding-top: 0.45vw;
  text-align: center;
  padding-bottom: 0.45vw;
}

.modal__header {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3.49vw;
}

.modal__body {
  padding-top: 3.02vw;
}

.modal__text {
  font-family: var(--unb);
  font-weight: 400;
  font-size: 1.04vw;
  text-align: center;
  color: #fff;
}

.modal__textOps {
  margin-top: 1vw;
  font-family: var(--unb);
  font-weight: 900;
  font-size: 1.25vw;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 780px) {
  .modal__inputs {
    padding-top: 4.17vw;
  }

  .modal__header {
    padding-left: 29.79vw;
    padding-right: 21.35vw;
    padding-top: 3.49vw;
  }

  .reg__button {
    padding: 0.83vw 17.23vw 0.83vw 8.23vw
  }

  .reg__btn {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .reg__button {
    font-size: 2vw;
    padding: 0.83vw 27.23vw 0.83vw 8.23vw;
  }

  .modal__textOps {
    font-family: 'Roboto';
    font-size: 3vw;
  }

  .modal__text {
    font-size: 3.5vw !important;
  }

  .modal__button {
    font-size: 2.4vw !important;
  }

  .modal__header {
    padding-left: 38.79vw !important;
  }

  .modal__header img {
    width: 16.25vw !important;
  }

  .logreg__main {
    font-size: 3.5vw !important;
  }

  .modal__label {
    font-size: 3.2vw !important;
  }

  .modal__input {
    height: 3.9vw !important;
    font-size: 2.5vw !important;
    width: 62.62vw !important;
  }

  .modal__signin,
  .haveacc__button,
  .haveacc__href,
  .haveacc__main {
    font-size: 2.5vw !important;
  }

  .haveacc__button img {
    width: 5.39vw !important;
  }
}
</style>
