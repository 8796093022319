import { createStore } from 'vuex'
let cart = window.localStorage.getItem('cart');

let sellCart = window.localStorage.getItem('sellCart')

export default createStore({
  state: {
    sellCart: sellCart ? JSON.parse(sellCart) : [],

    cart: cart ? JSON.parse(cart) : [],
    userLoged: localStorage.getItem('userLoged') ? localStorage.getItem('userLoged') : false,  
    userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : false,
  },
  getters: {
    SELL_CART(state){
      return state.sellCart

    },
    CART_PLS(state){
      return state.cart;
    },
    IsLogged(state){
      return state.userLoged; 
    },
    USER_NAME(state){
      return state.userName;  
    }
  },
  mutations: {
    SETSELLCART:(state, item) => {
      state.sellCart.push(item)
    },
    savesellCart(state) {
      window.localStorage.setItem('sellCart', JSON.stringify(state.sellCart));
    },
    removesell(state){
      window.localStorage.setItem('sellCart', JSON.stringify(state.sellCart));
    },
    RemoveSellCart:(state, i) => {
      state.sellCart.splice(i, 1)
    },


    RemoveCart:(state, i) => {
      state.cart.splice(i, 1)
    },
    SETCART:(state, product) => {
       state.cart.push(product)
    },
    saveCart(state) {
      window.localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    remove(state){
      window.localStorage.setItem('cart', JSON.stringify(state.cart));
    },

    Logout:(state) => {
      state.userName = '';
      state.userLoged = '';
    },
  },
  actions: {
    addToSellCart({commit}, item){
      commit('SETSELLCART', item)
      this.commit('savesellCart')
    },
    
    AddToCart({commit}, product){
      commit('SETCART', product)
      this.commit('saveCart');
      $('#ProductModal').modal('hide')
    },
    DeleteFromCart({commit}, i){
      commit('RemoveCart', i)
      this.commit('remove')
    },
    DeleteFromSellCart({commit}, i){
      commit('RemoveSellCart', i)
      this.commit('remove')
    },
    LogOut({commit}){
      commit('Logout')
    },
  },
  modules: {
  }
})
