import { createApp } from 'vue'
import './assets/style/fonts.css'
import App from './App.vue'
import router from './router'
import store from './store'
import Header from './components/Header'
import Footer from './components/CsFooter'
import NavBar from './views/NavBar'
import Shop from './components/Shop'
import Cases from './components/Cases'
import FAQ from './views/FAQ'
import HowSteps from './views/HowSteps'
import Account from './components/Account'
import SaleItem from './components/SaleItem'
import CartPage from './components/CartPage'
import OpsModal from './views/OpsModal'
import MoneyModal from './views/MoneyModal'
import PaymentError from './views/PaymentError'
import OutputModal from './views/OutputModal'
import jwt_decode from "jwt-decode";
import { createMetaManager } from 'vue-meta'
import loginModal from './components/loginModal.vue'
import regModal from './components/regModal.vue'
import VueGtag from "vue-gtag-next";


import {mapGetters} from 'vuex'

const Mixin = {
    data(){
        return {
            username: '',
            password: '',
            usernameR: '',
            passwordR: '',
            emailR: '',
        }
    },
    methods: {
        tryToBuy(){
            if(this.IsLogged){
                $("#exampleModal").modal("show");
            }
            else{
                console.log('asd')
                $("#exampleModal").modal("show");
            }
        },
        trackPage(path) {
            // this.$gtag.pageview(path); // Отправить событие отслеживания страницы для указанного пути
             this.$gtag.pageview({
                 page_path: path,
               })
           },
    },
    computed: {
        ...mapGetters(['IsLogged']),
    },
}

const app  = createApp(App)
app.use(store)
app.use(router)
app.use(createMetaManager())
app.mixin(Mixin)
app.use(VueGtag, {
    property: {
      id: "G-1GTSZ3J8EZ",
      params:{
        send_page_view: true,
      }
    }
  });

app.component('the-header', Header)
app.component('the-footer', Footer)
app.component('the-navbar', NavBar)
app.component('the-shop', Shop)
app.component('the-cases', Cases)
app.component('the-faq',FAQ)
app.component('login-modal', loginModal)
app.component('reg-modal', regModal)
app.component('how-steps', HowSteps)
app.component('the-account', Account)
app.component('cart-page', CartPage)
app.component('ops-modal', OpsModal)
app.component('money-modal', MoneyModal)
app.component('payment-error', PaymentError)
app.component('output-modal', OutputModal)
app.component('sale-items', SaleItem)



app.mount('#app')

