<template>
  <div class="stock" style="overflow-x: hidden; overflow-y: hidden">
    <div class="inner">
      <div class="stock__images row">
        <div class="stock__left col">
          <img src="@/assets/img/kek.svg" class="kekmob" alt="">
          <p class="stock__main">Обменивай лучшие скины</p>
          <p class="stock__sec">
            megacase.KZ - это грамотное управление<br />
            вашими кейсами в Steam. В нашем магазине<br />
            действуют постоянные скидки.
          </p>
          <div class="stock__buttons">
            <button class="stock__btnleft" onclick="window.location.href = '/shop';">Перейти в магазин</button>
            <button class="stock__btnright" onclick="window.location.href = '/sale';">Продать предметы</button>
          </div>
        </div>
        <div class="stock__right col">
          <img src="@/assets/img/bgstock.svg" alt="" style="width: 46.094vw; position: absolute;right: 10%;top: 13.2%;"
            class="stock__bg" />
        </div>
      </div>
      <div class="mobstoc">
        <div class="mobstockbtns">
          <button class="topstc">Перейти в магазин</button>
          <button class="botstc">Продать предметы</button>
        </div>
        <p class="stock__sec2">
          megacase.KZ - это грамотное управление<br />
          вашими кейсами в Steam. В нашем магазине<br />
          действуют постоянные скидки.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
  </div>
  <div class="container-fluid">
    <div class="cases">
      <div class="cases__content">
        <div class="cases__main">
          <div class="cases__sales">
            <img src="@/assets/img/flash.svg" alt="" style="width:2.08vw" />
            <p class="cases__sales__text">Акции и скидки</p>
          </div>

        </div>
        <div class="cases__cases row">
          <div class="case__content casecon col-lg-3 col-sm-6 col-6">
            <p class="case__frame">-10%</p>

            <img src="@/assets/img/magic.png" class="case1" alt="" style="width:331px; height: 248px" />
            <p class="case__desc">AK-47 | Neon Rider</p>
            <button class="case__btn" @click="tryToBuy">22 990 ₸</button>
          </div>
          <div class="case__content casecon casecon2 col-lg-3 col-sm-6 col-6">
            <p class="case__frame">NEW</p>

            <img src="@/assets/img/toxic.png" class="case2" alt="" style="width:331px; height: 248px" />
            <p class="case__desc ">M4A4 | Desolate Space</p>
            <button class="case__btn" @click="tryToBuy">5 490 ₸</button>
          </div>
          <div class="case__content casecon casecon3 col-lg-3 col-sm-6 col-6">
            <p class="case__frame">-5%</p>

            <img src="@/assets/img/ice.png" class="case3" alt="" style="width:331px; height: 248px" />
            <p class="case__desc">Ледяной кейс</p>
            <button class="case__btn" @click="tryToBuy">10 290 ₸</button>
          </div>
          <div class="case__content casecon casecon3 col-lg-3 col-sm-6 col-6">
            <p class="case__frame">-10%</p>

            <img src="@/assets/img/gold.png" class="case4" alt="" style="width:331px; height: 248px" />
            <p class="case__desc">P2000 | Acid Etched</p>
            <button class="case__btn" @click="tryToBuy">140 ₸</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="popular__items">
    <div class="popular__weapons">
      <div class="popular__text__content">
        <img src="@/assets/img/knife.svg" alt="" style="width: 2.08vw" />
        <p class="popular__text">Популярные предметы</p>
      </div>
    </div>
    <div class="item__weapons__blocks row">

      <div class="item__block">
        <div class="item__block__content" @click="tryToBuy">
          <img src="@/assets/img/brain.png" alt="" style="width: 13.02vw" />
          <p class="weapon__name" style="margin-top:1.3vw">USP-S</p>
          <div class="weapon__price__content">
            <p class="weapon__from">от</p>
            <p class="weapon__price">9 000 ₸</p>
          </div>
        </div>
      </div>
      <div class="item__block">
        <div class="item__block__content" @click="tryToBuy">
          <img src="@/assets/img/m4a4.png" alt="" style="width: 13.02vw" />
          <p class="weapon__name" style="margin-top:1.6vw">M4A4</p>
          <div class="weapon__price__content">
            <p class="weapon__from">от</p>
            <p class="weapon__price">13 590 ₸</p>
          </div>
        </div>
      </div>
      <div class="item__block">
        <div class="item__block__content" @click="tryToBuy">
          <img src="@/assets/img/web.png" alt="" style="width: 13.02vw;margin-top:-1.46vw" />
          <p class="weapon__name" style="margin-top:-10px">Karambit </p>
          <div class="weapon__price__content">
            <p class="weapon__from">от</p>
            <p class="weapon__price">12 095 $</p>
          </div>
        </div>
      </div>
      <div class="item__block">
        <div class="item__block__content" @click="tryToBuy">
          <img src="@/assets/img/flame.png" alt="" style="width: 13.02vw" />
          <p class="weapon__name" style="margin-top:1.6vw">Desert Eagle</p>
          <div class="weapon__price__content">
            <p class="weapon__from">от</p>
            <p class="weapon__price">81 590 ₸</p>
          </div>
        </div>
      </div>
      <div class="item__block">
        <div class="item__block__content" @click="tryToBuy">
          <img src="@/assets/img/butterfly.png" alt="" style="width: 13.02vw" />
          <p class="weapon__name">Butterfly </p>
          <div class="weapon__price__content">
            <p class="weapon__from">от</p>
            <p class="weapon__price">13 109 $</p>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="container-fluid">
    <div class="popular__mob">
      <div class="popular__weapons">
        <div class="popular__text__content">
          <img src="@/assets/img/knife.svg" alt="" style="width: 2.08vw" />
          <p class="popular__text">Популярные предметы</p>
        </div>
      </div>
      <div class="popmob row">
        <div class="col-6">
          <div class="popmob__content">
            <div class="d-flex justify-content-center">
              <img src="@/assets/img/brain.png" alt="" />
            </div>
            <div class="popmob__text">
              <span>USP-S</span>
              <div class="d-flex align-items-center">
                <small>от</small>
                <p class="m-0">9000 ₸</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="popmob__content">
            <div class="d-flex justify-content-center">
              <img src="@/assets/img/m4a4.png" alt="" />
            </div>
            <div class="popmob__text">
              <span>USP-S</span>
              <div class="d-flex align-items-center">
                <small>от</small>
                <p class="m-0">9000 ₸</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="popmob__content">'
            <div class="d-flex justify-content-center">
              <img src="@/assets/img/web.png" class="web" alt="" style="margin-top:-7%" />
            </div>
            <div class="popmob__text">
              <span>USP-S</span>
              <div class="d-flex align-items-center">
                <small>от</small>
                <p class="m-0">9000 ₸</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="popmob__content">
            <div class="d-flex justify-content-center">
              <img src="@/assets/img/flame.png" alt="" />
            </div>
            <div class="popmob__text">
              <span>USP-S</span>
              <div class="d-flex align-items-center">
                <small>от</small>
                <p class="m-0">9000 ₸</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="popmob__content">
            <div class="d-flex justify-content-center">
              <img src="@/assets/img/butterfly.png" alt="" />
            </div>
            <div class="popmob__text">
              <span>USP-S</span>
              <div class="d-flex align-items-center">
                <small>от</small>
                <p class="m-0">9000 ₸</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="popmob__content">
            <router-link to="/shop" @click="trackPage('/shop')">
              <div class="d-flex justify-content-center poplinkimg">
                <img src="@/assets/img/poplink.svg" class="img-fluid" alt="" />
              </div>
              <div class="text-center poplink">
                <p>СМОТРЕТЬ ВСЕ ПРЕДМЕТЫ</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <the-faq></the-faq>
</template>

<script>
import jwt_decode from "jwt-decode";
import { useMeta } from 'vue-meta'
export default {
  data() {
    return {
      mamasha: null,
    }
  },
  computed: {

  },
  mounted() {

    var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2YWxlcmEiOiJyYWJvdGFpIn0.tFfF254owDErX9DrK8tT0PzRKCcwPPyVkanEGb_WcMM";
    var decoded = jwt_decode(token);

    this.mamasha = decoded.valera

  },
  setup() {
    return {
      modules: [Navigation],
    },
      useMeta(
        {
          title: 'Главная',
          description: [
            { content: 'Главная, cs-market, кейсы, маркетплейс, магазин' }
          ]
        }
      )
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

:root {
  --unb: 'Unbounded', cursive;
  --nun: 'Nunito', sans-serif;
}
</style>

<style scoped>
.kekmob {
  display: none;
}

.stock__bg {
  z-index: 1;
}

.botstc {
  background: rgba(170, 0, 26, 0.4);
  border: 1px solid #AA001A;
  border-radius: 5px;
  padding: 13px 0;
  width: 100%;
  margin-top: 10px;

  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  font-family: var(--nun);

}

.topstc {
  background: #AA001A;
  border-radius: 5px;
  border: 0;
  padding: 13px 0;
  width: 100%;

  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  font-family: var(--nun);
}

.mobstoc {
  padding-left: 2.21vw;
  padding-right: 2.21vw;
  z-index: 100;
  margin-top: 37px;
  display: none;
}

.stock__sec2 {
  margin-top: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  font-family: var(--nun);
}

.poplinkimg {
  padding-top: 15px;
}

.poplink {
  padding: 19.5px 0;
}

.poplink p {
  font-family: var(--unb);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}

.popmob .col-6 {
  margin-top: 6px;
  padding-right: 3px;
  padding-left: 3px;
}

.popmob__text {
  padding: 26px 0 16px 12px;
}

.popmob__text span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-family: var(--unb);
  color: #fff;
}

.popmob__text small {
  font-size: 12px;
  font-family: var(--unb);
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
  margin-right: 5px;
}

.popmob__text p {
  font-family: var(--unb);
  font-weight: 400;
  color: #fff;
  font-size: 14px;
}

.popmob__content img {
  width: 135px;
  height: 101px;
}

.popmob__content {
  background: radial-gradient(82.39% 82.39% at 50% 50%, #1E1D21 0%, #3C3B3F 100%);
  transition: background .3s ease;
}

.popmob__content:hover {
  background: radial-gradient(82.21% 62.79% at 50% 50%, #3F7AF5 0%, #03266F 100%)
}

.popular__mob {
  padding: 100px 0;
  padding-left: 5.21vw;
  padding-right: 5.21vw;
  display: none;
}

.casecon {
  margin-bottom: 20px;
  transition: all .3s ease;
}

.casecon:hover {
  background: rgba(217, 217, 217, 0.1);
  box-shadow: 1px 0px 10px rgb(0 0 0 / 25%);
  border-radius: 20px;
}

.mtc {
  margin-top: 1.5rem;
}

.weapon__price {
  font-weight: 400;
  font-size: 1.04vw;
  font-family: var(--unb);
}

.weapon__from {
  margin-right: 0.5vw;
  font-weight: 400;
  font-size: 1.04vw;
  color: rgba(255, 255, 255, 0.3);
  font-family: var(--unb);
}

.weapon__name {
  font-weight: 500;
  font-size: 1.25vw;
  font-family: var(--unb);
}

.weapon__price__content {
  display: flex;
}

.item__block__content {
  cursor: pointer;
  width: 17.45vw;
  height: 20.36vw;
  color: #fff;
  padding-top: 2.60vw;
  padding-left: 2.19vw;
  padding-right: 2.24vw;
  transition: all .3s ease;
  background: radial-gradient(82.39% 82.39% at 50% 50%,
      #1e1d21 0%,
      #3c3b3f 100%);
}

.item__block__content:hover {
  transition: all .3s ease;
  background: radial-gradient(82.21% 62.79% at 50% 50%, #180105 0%, #AA001A 100%)
}


.item__weapons__blocks {
  display: flex;
  justify-content: space-between
}

.shit {
  margin-top: 2.6vw;
}

.popular__cases__item {
  text-align: center;
}

.cases__item__btn {
  background: #2c6df5;
  border-radius: 5px;
  color: #fff;
  border: 0;
  font-weight: 400;
  font-size: 1.25vw;
  padding: 0.63vw 2.60vw 0.63vw 2.60vw;
}

.cases__item__desc {
  font-weight: 400;
  font-size: 1.25vw;
  color: #fff;
}

.popular__cases__content {
  display: flex;
  align-items: center;
}

.popular__text {
  margin-left: 1.25vw;
  font-weight: 500;
  font-size: 2.08vw;
  color: #fff;
  font-family: var(--unb);
  margin-bottom: 64px;
}

.popular__text__content {
  display: flex;
  align-items: baseline;
}

.popular__items {
  padding-top: 7.81vw;
  padding-left: 5.21vw;
  padding-right: 5.21vw;
}

.case__btn {
  background: #AA001A;
  border-radius: 5px;
  color: #fff;
  padding: 0.63vw 2.60vw 0.63vw 2.60vw;
  border: 0;
  font-weight: 400;
  font-size: 1.25vw;
  margin-bottom: 3.13vw;
  font-family: var(--unb);
}

.case__desc {
  font-weight: 400;
  font-size: 1.25vw;
  font-family: var(--unb);
  color: #ffff;
}

.case__frame {
  margin-left: 13.44vw;
  font-weight: 400;
  font-size: 1.25vw;
  color: #fff;
  background: rgba(170, 0, 26, 0.4);
  border: 1px solid #AA001A;
  box-sizing: border-box;
  border-radius: 5px;
  width: 5.21vw;
  padding: 0.52vw 1.25vw 0.52vw 1.05vw;
  font-family: var(--nun);
}

.cases__cases {
  text-align: center;
  padding: 0 25px;
}

.cases__href {
  color: #fff;
  font-weight: 400;
  font-size: 1.04vw;
  font-family: var(--nun);
}

.cases__sales__text {
  margin-left: 1.25vw;
  font-weight: 500;
  font-size: 2.08vw;
  font-family: var(--unb);
}

.cases__main {
  padding-left: 2.6vw;
  padding-right: 2.6vw;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.cases__sales {
  display: flex;
  align-items: baseline;
}

.stock__buttons {
  display: flex;
}

.stock__btnleft {
  background: #AA001A;
  border-radius: 5px;
  border: 0;
  padding: 1.25vw 2.60vw 1.25vw 2.60vw;
  font-size: 1.04vw;
  color: #fff;
  margin-right: 1.25vw;
  font-family: var(--nun);
}

.stock__btnright {
  background: rgba(170, 0, 26, 0.4);
  border: 1px solid #AA001A;
  border-radius: 5px;
  padding: 1.25vw 2.60vw 1.25vw 2.60vw;
  font-size: 1.04vw;
  color: #fff;
  font-family: var(--nun);
}

.stock__main {
  text-align: left;
  font-style: normal;
  font-weight: 900;
  font-size: 4.17vw;
  font-family: var(--unb);
  line-height: 99px;
}

.stock__sec {
  margin-bottom: 4.27vw;
  text-align: left;
  font-weight: 400;
  font-size: 1.25vw;
  font-family: var(--nun);

}

.linkwithout {
  outline: none;
  text-decoration: none;
}

.slct {
  position: absolute;
  left: 45%;
  bottom: 0;
}

.cases__popular__content {
  display: flex;
  justify-content: center;
}

.casesLeft {
  position: absolute;
  width: 1.35vw;
  left: 10px;
  top: 50%;
  cursor: pointer;
  z-index: 4;
}

.casesRight {
  position: absolute;
  width: 1.35vw;
  right: 10px;
  top: 50%;
  cursor: pointer;
  z-index: 4;
}

.cases_p {
  padding-bottom: 5.83vw;
}

.pl__pr {
  margin-left: 2.86vw;
  margin-right: 2.86vw;
}

.cases__price {
  font-weight: 800;
  font-size: 1.88vw;
  line-height: 2.5vw;
  text-align: center;
  letter-spacing: 0.065em;
  background: linear-gradient(83.8deg, #c62c44 15.62%, #ff0027 91.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 5px 4px 30px #9a1f36, 2px 4px 4px #9a1f36;
}

.cases__desc {
  font-size: 1.35vw;
  text-align: center;
  color: #fff;
}

.cases__popular {
  display: grid;
  margin-top: 3.96vw;
}

.cases__avatar {
  padding-top: 6.35vw;
}

.stock__btn {
  border: 0;
  background: linear-gradient(83.8deg, #c62c44 15.62%, #ff0027 91.62%);
  border-radius: 1.56vw;
  font-weight: 700;
  font-size: 1.46vw;
  color: #fff;
  padding: 0.89vw 1.46vw 0.89vw 1.46vw;
}

.stock__button {
  margin-left: 5vw;
  padding-bottom: 5.21vw;
}

.stock__button2 {
  padding-bottom: 5.21vw;
}

.stock__desc22 {
  font-weight: 700;
  font-size: 2.08vw;
  color: #fff;
}

.stock__desc2 {
  margin-left: 5vw;
  font-weight: 700;
  font-size: 2.08vw;
  color: #fff;
  margin-top: -10.15vw;
}

.stock__left {
  margin-top: 4.66vw;
  margin-left: 4.48vw;
}

.stock__left2 {
  margin-top: 4.53vw;
  margin-left: 4.48vw;
}

.stock__right2 {
  margin-top: 10.04vw;
}

.stock__images {
  display: flex;
  justify-content: space-between;
  padding-bottom: 177px;
}

.stock {
  -webkit-background-size: cover;
  position: relative;
  height: 50.2vw !important;
}

.inner {
  padding-top: 6.46vw;
  background-image: url("@/assets/img/bgmarket.png");
  background-size: 100vw;
  position: relative;
  z-index: 3;
  color: #fff;
  text-align: center;
}

.cases {
  margin-top: 5.63vw;
  padding-left: 5.21vw;
  padding-right: 5.21vw;
  position: relative;
}

.cases__content {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border: 3px solid #AA001A;
  border-radius: 20px;
  padding-top: 2.6vw;
  /* padding-top: 12.19vw; */
}

.stock__text {
  text-shadow: 5px 4px 30px #561f8c, 2px 4px 4px #561f8c;
  color: #f7f7f7;
  font-weight: 800;
  font-size: 3.13vw;
}

hr {
  border: 3px solid #ffffff;
  box-shadow: 0px 2px 4px #9028f6;
  filter: blur(1px);
  width: 10.68vw;
  text-align: center;
}

@media (max-width: 1440px) {
  .stock__right img {
    width: 63.66vw !important;
    top: 12.5% !important;
  }

  .stock__main {
    line-height: 80px;
  }

  .case__content img {
    width: 200px !important;
    height: 165px !important;
  }
}

@media (max-width: 1200px) {
  .cases__content {
    background-size: 100vw;
  }
}

@media (max-width: 1024px) {
  .stock__main {
    line-height: 60px;
  }

  .accordion>.card:not(:last-of-type) {
    border-bottom: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .cases__content {
    background-size: 110vw;
  }

  .cases__href {
    font-size: 1.44vw;
  }

  .case__content img {
    width: 160px !important;
    height: 135px !important;
  }
}

@media screen and (max-width: 768px) {
  .stock__right img {
    width: 58.66vw !important;
    top: 10% !important;
  }

  .cases__content {
    background-size: 237vw;
  }

  .mtc {
    margin-top: 0.5rem;
  }

  .popular__items {
    display: none;
  }

  .popular__mob {
    display: block;
  }

  .popular__text {
    margin-bottom: 2.92vw
  }

  .stock__main {
    line-height: 50px;
  }

  .stock__images {
    padding-bottom: 26px;
  }

  .stock__sec {
    font-size: 1.65vw;
  }

  .stock__btnright,
  .stock__btnleft {
    font-size: 1.54vw;
  }

  .stock__buttons {
    margin-left: -5.789999999999999vw;
  }
}

@media screen and (max-width: 480px) {
  .mobstoc {
    display: block;
  }

  .stock__bg {
    width: 71.66vw !important;
  }

  .kekmob {
    display: block;
    margin-bottom: 20px;
  }

  .stock__main {
    font-size: 18px;
    font-weight: 900;
    line-height: 29px;
  }

  .stock__buttons,
  .stock__sec {
    display: none;
  }


  .poplinkimg {
    padding-top: 53px;
  }

  .poplinkimg img {
    width: 56px;
    height: 46px;
  }

  .web {
    margin-top: -13.8% !important;
  }

  .item__block__content {
    height: 31.36vw;
  }

  .weapon__price,
  .weapon__from {
    font-size: 1.75vw;
  }

  .cases__item__desc,
  .cases__item__btn,
  .weapon__name {
    font-size: 2vw;
  }

  .popular__text {
    font-size: 19px;
  }


  .popular__text__content img {
    width: 5vw !important;
  }

  .case__btn {
    font-size: 2.8vw;
    font-family: var(--unb);
  }

  .case4 {
    margin-bottom: -1vw;
  }

  .mtc {
    margin-top: 1.2vw;
  }

  .case__desc {
    font-size: 3.1vw;
    font-family: var(--nun);
  }

  /* .case1, .case2, .case3, .case4{
    width: 19.67vw !important;
  } */
  .case__frame {
    font-size: 2vw;
    margin-left: 25.44vw;
    width: 7.210000000000001vw;
    font-family: var(--unb);
  }

  .cases__content {
    background-size: 358.58vw;
  }

  .cases__href {
    margin-top: 2.2vw;
    font-size: 2vw;
    font-family: var(--nun);
  }

  .cases__sales__text {
    font-size: 20px;
  }

  .cases__sales img {
    width: 4.67vw !important;
  }

  .stock__sec {
    font-family: 'Roboto';
    font-size: 1.95vw;
  }

  .inner {
    background-repeat: no-repeat;
  }

  .stock {
    height: 134.2vw !important;
    padding-top: 0;
  }

  .stock__desc,
  .stock__desc2 {
    font-size: 2.67vw;
  }

  .mobstoc {
    margin-top: 53px;
  }

  .stock__right img {
    width: 62.86vw !important;
    right: 0% !important;
    top: 10% !important;
  }

  .stock__left {
    margin-top: 3.66vw;
  }

  .inner {
    padding-top: 17.47vw;
    background-size: 125vw;
  }

  .stock__text {
    font-size: 3.93vw;
  }

  .stock__button {
    margin-top: -4vw;
    padding-bottom: 0;
  }

  .stock__buttons {
    margin-left: -0.78vw;
  }

  .stock__btnleft,
  .stock__btnright {
    font-size: 1.7vw;
  }

  .cases__mainimg {
    margin-left: 29vw !important;
  }

  .cases__mainimg2 {
    margin-left: 26vw !important;
  }

  .stock__btn {
    font-size: 2.67vw;
  }

  .stock__img {
    width: 4.08vw !important;
  }

  hr {
    border: 2px solid #ffffff;
  }

  .cases__popular {
    flex-wrap: wrap;
  }

  .cases__popular__content img {
    width: 27vw !important;
  }

  .cases__avatar img {
    width: 7vw !important;
  }

  .cases__desc {
    font-size: 3.2vw;
  }

  .cases__price {
    font-size: 3.5vw;
  }

  .case__content img {
    width: 110px !important;
    height: 85px !important;
  }

  .cases {
    padding-left: 2.21vw;
    padding-right: 2.21vw;
  }
}

@media (max-width: 390px) {
  .p {
    height: 14vw;

  }

  .btn-link,
  .card-body {
    font-size: 12px;
  }

  .faq__main {
    font-size: 16px;
  }

  .cases__sales__text {
    font-size: 16px;
  }

  .popular__text {
    font-size: 16px;
  }

  .case__desc {
    font-size: 2.9vw;
    font-family: var(--nun);
  }


  .stock__right img {
    top: 26% !important;
  }

  .stock {
    height: 141.2vw !important;
  }

  .stock__sec2 {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px) {
  .p {
    height: 80px;
    font-size: 5.34vw;
  }

  .poplinkimg {
    padding-top: 36px;
  }

  .web {
    margin-top: -18% !important;
  }

  .faq__main {
    font-size: 16px;
  }

  .popular__text {
    font-size: 14px;
    font-weight: 400;
  }

  .cases__sales__text {
    font-size: 16px;
    font-weight: 400;
  }

  .case__desc {
    white-space: nowrap;
  }

  .case__content img {
    width: 80px !important;
    height: 60px !important;
  }

  .cases__content {
    background-size: 387.58vw;
  }

  .inner {
    padding-top: 22.6vw;
  }

  .stock__left {
    z-index: 100;
  }

  .stock__main {
    font-size: 16px;
  }

  .mobstoc {
    margin-top: -11px;
  }

  .stock__right img {
    display: none;
  }


  /* .poplinkimg {
    padding-top: 29px;
    width: 70px;
  }

  .poplink p {
    font-family: var(--unb);
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
  } */

  .stock__sec2 {
    font-size: 12px;
  }

  .stock__buttons {
    margin-left: -1.78vw
  }

  .btn-link,
  .card-body {
    font-size: 9px;
  }
}
</style>
