<template>
    <div class="modal fade" id="SellProductModal" tabindex="-1" aria-labelledby="ProductModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="text-center mt-4">
                        <h1>Вы продаете</h1>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{ product.name }}</p>

                        <div class="d-flex align-items-center justify-content-center mt-4">
                            <p class="m-0">Введите цену:</p>
                            <input type="number" class="ml-4" placeholder="Введите сумму" v-model="price">

                        </div>
                        <div class="mt-4">
                            <small id="smalllock">Сумма должна быть больше нуля</small>
                        </div>


                        <button class="mt-4 mb-4" id="tovarbtn" @click="test(product)">Выбрать</button>

                    </div>
                </div>
                <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                <button type="button" class="btn btn-primary">Сохранить изменения</button>
            </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { shallowEqual } from '@babel/types'
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            price: 0,
        }
    },
    props: {
        product: Object,
    },
    methods: {
        ...mapActions([
            'addToSellCart'
        ]),
        closeProduct() {
            $('#ProductModal').modal('hide')
        },
        test(product) {
            product.price = this.price

            if (this.price <= 0) {
                document.querySelector('#smalllock').classList.add('smallactive')
            }
            else if (this.SELL_CART.filter((item) => item.classid === product.classid).length === 0 == false) {
                document.querySelector('#tovarbtn').innerHTML = 'Товар уже добавлен'
            }
            else {
                document.querySelector('#smalllock').classList.remove('smallactive')
                this.addToSellCart(product);
                this.price = null
                $('#SellProductModal').modal('hide');
            }



        },
        addToCartModal(product) {
            let test = this.SELL_CART.find((item, index, arr) => {
                return product.classid == 3946324730
            })
            product.price = this.price
            if (this.IsLogged) {
                this.addToSellCart(product);
                this.price = null
                $('#SellProductModal').modal('hide');
            }
            else {
                $('#SellProductModal').modal('hide');
                $("#exampleModal").modal("show");
            }
        }
    },
    computed: {
        ...mapGetters(["SELL_CART"])
    },
}
</script>

<style scoped>
.smallactive {
    display: block !important;
}

small {
    color: red;
    display: none;
}

.modal-body button {
    background: #AA001A;
    box-shadow: 0px 0px 10px #AA001A;
    padding: 16px 241px;
    border: 0;

    color: #fff;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    font-family: var(--nun);
}

.modal-body input {
    height: 60px;
    background: transparent;
    box-shadow: 0px 0px 4px #AA001A;
    border: 0;
    color: #fff;
    font-family: var(--unb);
    padding-left: 7px;
}

.modal-body p {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    font-family: var(--nun);
    color: #fff;
}

.modal-header h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    font-family: var(--unb);
}

.productModal__priceName {
    font-weight: 700;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
}

li::before {
    content: '';
    border-bottom: 1px dashed #ccc;
    flex-grow: 1;
    order: 2;
    margin: 0 5px;
}

.modal__value {
    order: 3;
}

.modal-body {
    margin-top: unset;
}

.modal-dialog {
    min-width: 51.510vw;
}

#ProductModal {
    color: white;
}

#ProductModal .modal-header .close {
    padding: 1.5rem 2rem 1rem 1rem;
    color: white;
}

#ProductModal .modal-content {
    color: white;
    background-color: #141721;
}

.productModal__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: #252331; */
    padding: 1.042vw 1.563vw 2.083vw 1.563vw;
    border-radius: 10px;
}

.productModal__img {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.productModal__name {
    font-weight: 700;
    font-size: 1.354vw;
    text-align: center;
}

.productModal__table {
    margin-top: 1.823vw;
    /* background-color: #3C3537; */
    border-radius: 10px;
    padding-left: 0;
}

.productModal__row {
    padding: 1.042vw 0;
    font-weight: 500;
    font-size: 1.146vw;
    display: flex;
    justify-content: space-between;
}

.productModal__tableCenter {
    /* border-top: 1px solid #F6F6F6CC;
    border-bottom: 1px solid #F6F6F6CC; */
}

.productModal__btn {
    background: rgba(44, 109, 245, 0.2);
    border-color: #2C6DF5;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    font-size: 1.146vw;
}

.productModal__btnM {
    background: rgba(44, 109, 245, 0.2);
    border-color: #2C6DF5;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    font-size: 1.146vw;
}

.modal-content {
    background: #141721;
    border: 1px solid #AA001A;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #aa001a;
    border-radius: 0 !important;
}

.productModal__price {
    font-weight: 400;
    font-size: 1.667vw;
    font-family: 'Roboto', sans-serif;
}

@media screen and (min-width:481px) {
    .productModal__btnM {
        display: none;
    }
}

@media screen and (max-width:480px) {
    .productModal__name {
        font-size: 14px;
    }

    .productModal__table {
        border-radius: 5px;
    }

    .productModal__row {
        font-size: 12px;
    }

    .productModal__btnM {
        font-size: 8px;
    }

    .productModal__price {
        font-size: 14px;
    }

    .productModal__btn {
        display: none;
    }

    .productModal__card {
        padding: 3.042vw 1.563vw 4.083vw 1.563vw;
    }
}
</style>