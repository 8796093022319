<template>
  <div class="main" v-if="IsLogged == 'true' || IsLogged == 'email'">
    <p class="lc">Личный кабинет</p>
    <div class="usermob">
      <div class="userinfomob">
        <div class="d-flex align-items-center">
          <img src="@/assets/img/fullsavatar.png" class="img-fluid" alt="">

          <div class="ml-4">
            <p class="m-0">{{ userName }}</p>
            <span class="d-flex align-items-center m-0"><img src="@/assets/img/wallet2.svg" class="mr-2" alt=""> {{
              userBalance }}
              ₸</span>
            <a href="#" class="d-flex mt-2"><img src="@/assets/img/steamico.svg" style="width: 5.86vw !important"
                class="steam__ico mr-2" />
              {{ userName }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="user">
      <div class="user__info">
        <div class="user__info-group">
          <img src="@/assets/img/fullsavatar.png" alt="" style="width: 8.33vw" class="user__ava" />
          <div class="user__margin" style="margin-left: 2.08vw">
            <p class="user__info-name">{{ userName }}</p>
            <a href="#" class="steam__href">
              <img src="@/assets/img/steamico.svg" style="width: 1.46vw" class="steam__ico" />
              {{ userName }}
            </a>
          </div>
        </div>
      </div>
      <div class="user__credits">
        <div class="user__credits-money">
          <img src="img/wallet.svg" alt="" />
          <p class="user__count">{{ userBalance }} ₸</p>
        </div>
        <div class="user__credits-buttons">
          <button class="user__btn" data-toggle="modal" data-target="#moneyModal" @click="isIn = true">
            <img src="@/assets/img/dollar.svg" alt="" style="width: 1.25vw" />Пополнить баланс
          </button>
          <button class="user__btn" style="background: rgba(170, 0, 26, 0.4)" data-toggle="modal"
            data-target="#moneyModal" @click="isIn = false">
            <img src="@/assets/img/money.svg" alt="" style="width: 1.2vw" />Вывести средства
          </button>
        </div>
      </div>
    </div>



    <div class="mobbtn">
      <button class="moneyin d-flex align-items-center justify-content-center" data-toggle="modal"
        data-target="#moneyModal" @click="isIn = true">
        <img src="@/assets/img/dollar.svg" alt="" style="width: 6.25vw; margin-right:13px" />Пополнить
        баланс</button>

      <button class="moneyout d-flex align-items-center justify-content-center" data-toggle="modal"
        data-target="#moneyModal" @click="isIn = false"><img src="@/assets/img/money.svg" alt=""
          style="width: 6.2vw; margin-right:13px" />Вывести
        средства</button>
    </div>

    <hr>

    <div class="data">

      <div class="data__user d-flex align-items-center justify-content-center">
        <button :class="['data__user__btn1', { 'data__user__btn--active': userData == 1 }]" @click="userData = 1">Личные
          данные</button>
        <button :class="['data__user__btn2', { 'data__user__btn--active': userData == 2 }]"
          @click="userData = 2">Транзакции</button>
        <button :class="['data__user__btn3', { 'data__user__btn--active': userData == 3 }]" @click="userData = 3">Баланс
          аккаунта</button>
      </div>

      <hr>

      <div class="data__all container-fluid">

        <div :class="['data__password row', { 'data--active': userData == 1 }]">

          <div class="col-xl-6 col-12">
            <form action="/email" method="post">
              <div class="mb-4 data__settings">

                <div class="d-flex justify-content-between align-items-center">
                  <label for="email" class="form-label">E-mail</label>
                  <button type="submit" style="background:transparent; border:0"><img src="@/assets/img/edit.svg"
                      alt=""></button>
                </div>


                <input type="email" class="form-control" name="email" id="email" placeholder="Ваша почта">
              </div>
            </form>



            <form action="/password" method="post" class="form__pass">
              <div class="mb-4 data__settings">
                <label for="current-password" class="form-label">Старый пароль</label>
                <input type="password" class="form-control" name="current-password" id="current-password"
                  placeholder="Старый пароль">
              </div>

              <div class="mb-4 data__settings">
                <label for="new-password" class="form-label">Новый пароль</label>
                <input type="password" class="form-control" name="new-password" id="new-password"
                  placeholder="Новый пароль">
                <button @click="showPass()" style="background:transparent; border:0;position:absolute; top: 55%; right:2%"
                  type="button">

                  <img src="@/assets/img/showpass.svg" alt="">
                </button>
              </div>

              <div class="mb-4 data__settings">
                <label for="new-password" class="form-label">Повторите пароль</label>
                <input type="password" class="form-control" name="new-password" id="repeatpass"
                  placeholder="Повторите пароль">
                <button @click="showRepeat()"
                  style="background:transparent; border:0;position:absolute; top: 55%; right:2%" type="button">
                  <img src="@/assets/img/showpass.svg" alt="">
                </button>
              </div>

              <button type="submit" class="password__btn">Сменить пароль</button>
            </form>
          </div>

          <div class="col-xl-6 col-12">
            <form action="/trade-link" method="post" class="form__trade">
              <div class="mb-4 trade-link">
                <div class="d-flex justify-content-between align-items-center">
                  <label for="trade" class="form-label">Трейд-ссылка</label>

                  <a href="#">Где получить?</a>
                </div>

                <input type="text" class="form-control" name="trade" id="trade"
                  placeholder="https://steamcommunity.com/tradeoffer/new/?partner=969138412&token=NJff8Nnr">

                <button type="button" @click="copy()"
                  style="position:absolute; top:55%; right:1%; border:0; background:transparent">
                  <img src="@/assets/img/link.svg" alt="">
                </button>

              </div>
              <small id="copyed" class="mb-3">Ссылка скопирована</small>

              <div>
                <button type="submit" class="savetrade">Сохранить трейд-ссылку</button>
              </div>
            </form>
          </div>

        </div>

        <div :class="['transactions', { 'data--active': userData == 2 }]">

          <div class="transactions__body">
            <div class="text-center pt-5 transerror">
              <h1>Список транзакций будет доступен, когда Вы<br> что-то купите или продадите</h1>

              <router-link to="/shop" @click="trackPage('/shop')">
                <button class="trshop">Перейти в магазин</button>
              </router-link>

              <router-link to="/sale" @click="trackPage('/sale')">
                <button class="trsale">Продать предметы</button>
              </router-link>
            </div>
            <!-- <table class="table table-borderless text-center">
              <thead>
                <tr>
                  <th scope="col">Тип</th>
                  <th scope="col">Наименование</th>
                  <th scope="col">Цена</th>
                  <th scope="col">Сумма</th>
                  <th scope="col">Дата</th>
                  <th scope="col">Статус</th>
                </tr>
              </thead>
              <tbody v-for="tran in trans" :key="tran.id">
                <tr>
                  <td>{{ tran.type == false ? 'Покупка' : 'Продажа' }}</td>
                  <td class="table__name">

                    <div class="table__img">
                      <img :src="tran.img" alt="Изображение">
                    </div>

                    {{ tran.name }}
                  </td>
                  <td>{{ tran.price.toLocaleString() }} ₸</td>
                  <td> {{ tran.type == false ? '+' : '-' }} {{ tran.count * tran.price }} ₸</td>
                  <td>{{ tran.date }}</td>
                  <td :class="[{ 'true': tran.status == true }, { 'false': tran.status == false }]">
                    {{
                      tran.status == true ?
                      'Совершено' : 'Отменено'
                    }}</td>
                </tr>

              </tbody>
            </table> -->
          </div>

          <div class="transmob__body">
            <div class="text-center">
              <h1>Список транзакций будет доступен, когда Вы что-то купите или продадите</h1>

              <router-link to="/shop" @click="trackPage('/shop')">
                <button class="trmshop">Перейти в магазин</button>
              </router-link>

              <router-link to="/sale" @click="trackPage('/sale')">
                <button class="trmsale">Продать предметы</button>
              </router-link>
            </div>
            <!-- <div>
              <div class="transmob__block" v-for="transm in transmob" :key="transm.id">
                <div class="d-flex align-items-center">
                  <div class="transmob__img">
                    <img :src="transm.img" style="max-width:77px" alt="">
                  </div>
                  <div class="transmob__desc ml-4 w-100">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                      <p class="m-0">{{ transm.name }}</p>
                      <div v-if="transm.status == true"><img src="@/assets/img/suc.svg" alt=""></div>
                      <div v-else><img src="@/assets/img/notsuc.svg" alt=""></div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                      <span class="m-0">{{ transm.date }}</span> <span class="ml-4">{{
                        transm.type == true ?
                        'Покупка' : 'Продажа'
                      }}</span>
                    </div>
                    <div class="d-flex justify-content-end">
                      <small
                        :class="[{ 'transmob__desc--suc': transm.status == true }, { 'transmob__desc--nosuc': transm.status == false }]">
                        {{
                          transm.type == true ? '+' + transm.price.toLocaleString() : '-' +
                        transm.price.toLocaleString()
                        }} ₸</small>
                    </div>
                  </div>

                </div>
                <hr>

              </div>


            </div> -->
          </div>

        </div>

        <div :class="['balance', { 'data--active': userData == 3 }]">

          <div class="balance__body">
            <div class="text-center pt-5" v-if="balanceReal.length <= 0">
              <h1>Список операций будет доступен, когда Вы<br> пополните или выведете средства с баланса</h1>
            </div>
            <table class="table table-borderless text-center" v-else>
              <thead>
                <tr>
                  <th scope="col">Тип</th>
                  <th scope="col">Сумма</th>
                  <th scope="col">Дата</th>
                  <th scope="col">Статус</th>
                  <th scope="col">Состояние счета</th>
                </tr>
              </thead>
              <tbody v-for="bal in balanceReal.slice().reverse()" :key="bal.id">
                <tr>
                  <td>{{ bal.type == 'card_pay' ? 'Пополнение' : bal.type == 'mobile_pay' ?
                    'Пополнение' : bal.type == 'card_return' ? 'Вывод' : bal.type ==
                      'mobile_return' ? 'Вывод' : bal.type }}</td>
                  <td> {{ bal.type == 'card_pay' ? '+' : bal.type == 'mobile_pay' ? '+' : bal.type
                    == 'card_return' ? '-' : bal.type == 'mobile_return' ? '-' : bal.type }} {{
    bal.amount }} ₸</td>
                  <td>{{ bal.datetime }}</td>
                  <td :class="[{ 'true': bal.status == 'Success' }, { 'false': bal.status == 'failure' }]">
                    {{ bal.status == 'Success' ? 'Совершено' : 'Отменено' }}</td>
                  <td>{{ bal.current_balance.toLocaleString() }} ₸</td>

                </tr>

              </tbody>
            </table>
          </div>

          <div class="balancemob">
            <div class="text-center" v-if="balanceReal.length <= 0">
              <h1>Список операций будет доступен, когда Вы пополните или выведете средства с баланса</h1>

              <button class="trmshop">Пополнить баланс</button>
              <button class="trmsale">Вывести средства</button>
            </div>
            <div class="balancemob__body" v-for="bal in balanceReal.slice().reverse()" :key="bal.id" v-else>
              <div class="balancemob__status d-flex justify-content-between align-items-center">
                <p class="mb-0">{{ bal.type == 'card_pay' ? 'Пополнение' : bal.type == 'mobile_pay' ?
                  'Пополнение' : bal.type == 'card_return' ? 'Вывод' : bal.type ==
                    'mobile_return' ? 'Вывод' : bal.type }}</p>
                <div class='d-flex balancemob__date'>
                  <p class="m-0">{{ bal.datetime }}</p>

                </div>
                <div v-if="bal.status == 'Success'"><img src="@/assets/img/suc.svg" alt=""></div>
                <div v-else><img src="@/assets/img/notsuc.svg" alt=""></div>
              </div>

              <div class="balancemob__money d-flex align-items-center mt-4">
                <div class="text-center">
                  <p>Сумма:</p>
                  <span>{{ bal.type == 'card_pay' ? '+' : bal.type == 'mobile_pay' ? '+' : bal.type
                    == 'card_return' ? '-' : bal.type == 'mobile_return' ? '-' : bal.type }} {{
    bal.amount }} ₸</span>
                </div>
                <div class="text-center">
                  <p>Состояние счета:</p>
                  <span>{{ bal.current_balance.toLocaleString() }} ₸</span>
                </div>
              </div>
              <hr>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
  <div class="text-center notSteam" v-else>
    <div class="notSteam__body">
      <div class="text-center">
        <img src="@/assets/img/steamLoginError.png" class="img-fluid" alt="">

        <h1>Чтобы просматривать эту страницу вы<br> должны быть авторизованы</h1>

        <p>Пожалуйста, авторизуйтесь и попробуйте еще раз!</p>


        <button data-toggle="modal" data-target="#loginModal">Авторизоваться</button>
      </div>
    </div>
  </div>



  <money-modal>

    <template #header>

      <div class="moneymodal__header text-center">

        <h1 v-if="isIn">Пополнить баланс</h1>
        <h1 v-else>Вывод средств</h1>

        <div class="moneymodal__headerType d-flex justify-content-center mt-4">
          <button :class="['balancebtn', { 'activeBtn': isIn == true }]" @click="isIn = true">Пополнить баланс</button>
          <button :class="['outputbtn', { 'activeBtn': isIn == false }]" @click="isIn = false">Вывести средства</button>
        </div>
      </div>

    </template>

    <template #body>
      <div v-show="isIn">

        <div class="moneymodal__body">

          <h2>Порядок действий для пополнения баланса</h2>

          <p class="mt-4">1. Подтвердите Ваше согласие с правилами нашей системы</p>

          <div class="form-check mt-4">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label xdkek" for="flexCheckDefault">
              Я согласен с <router-link @click="closeMoney(), trackPage('/privacy')" to="/privacy">пользовательским
                соглашением и
                политикой
                конфиденциальности</router-link>
            </label>
          </div>

          <p class="mt-4">2. Введите сумму, на которую Вы хотите пополнить личный счет, и нажмите на кнопку “Пополнить”.
            Вы будете переадресованы на сайт платежной системы, где сможете завершить платеж.</p>
        </div>

        <div class="footermod">


          <div class="moneymodal__bodyfot d-flex justify-content-between">
            <input v-model="paySum" type="number" name="money" class="text-right" placeholder="₸">

            <button @click="phonePur()" id="inButton">
              <div class="spinner-border text-info inSpinner" role="status">
                <span class="sr-only">Loading...</span>
              </div><span id="inBtn">Пополнить баланс</span>
            </button>
          </div>

          <div class="moneymodal__bodycounts d-flex mt-4">
            <button :class="['plsnot', { 'countActive': paySum == 500 }]" @click="paySum = 500">500</button>
            <button :class="[{ 'countActive': paySum == 1000 }]" @click="paySum = 1000">1000</button>
            <button :class="[{ 'countActive': paySum == 2000 }]" @click="paySum = 2000">2000</button>
            <button :class="[{ 'countActive': paySum == 5000 }]" @click="paySum = 5000">5000</button>
            <button :class="[{ 'countActive': paySum == 10000 }]" @click="paySum = 10000">10000</button>
          </div>

        </div>
      </div>

      <div v-show="isIn == false">
        <div class="moneymodal__body">

          <div class="d-flex align-items-start mt-4">
            <img src="@/assets/img/warning1.svg" alt="" class="imgmod">
            <p class="m-0">Вывод средств осуществляется только на подтвержденные карты, с которых было произведено
              пополнение.</p>
          </div>

          <div class="d-flex align-items-start mt-4">
            <img src="@/assets/img/warning1.svg" alt="" class="imgmod">
            <p class="m-0">Процесс обработки заявки обычно занимает меньше часа, но может занять до 3-х рабочих дней.
            </p>
          </div>

          <h2 class="mt-4">Порядок действий для пополнения баланса</h2>

          <p class="mt-4">1. Подтвердите Ваше согласие с правилами нашей системы</p>

          <div class="form-check mt-4">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label xdkek" for="flexCheckDefault">
              Я согласен с <router-link @click="closeMoney(), trackPage('/privacy')" to="/privacy">пользовательским
                соглашением и
                политикой
                конфиденциальности</router-link>
            </label>
          </div>

          <p class="mt-4">2. Введите сумму, на которую Вы хотите пополнить личный счет, и нажмите на кнопку “Пополнить”.
            Вы будете переадресованы на сайт платежной системы, где сможете завершить платеж.</p>
        </div>

        <div class="footermod">


          <div class="moneymodal__bodyfot d-flex justify-content-between">
            <input v-model="outSum" type="number" name="money" id="" class="text-right" placeholder="₸">
            <button @click="phoneOut()" id="outButton">
              <div class="spinner-border text-info outSpinner" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <span id="outBtn">Вывести средства</span>
            </button>
          </div>

          <div class="moneymodal__bodycounts d-flex mt-4">
            <button :class="['plsnot', { 'countActive': outSum == 500 }]" @click="outSum = 500">500</button>
            <button :class="[{ 'countActive': outSum == 1000 }]" @click="outSum = 1000">1000</button>
            <button :class="[{ 'countActive': outSum == 2000 }]" @click="outSum = 2000">2000</button>
            <button :class="[{ 'countActive': outSum == 5000 }]" @click="outSum = 5000">5000</button>
            <button :class="[{ 'countActive': outSum == 10000 }]" @click="outSum = 10000">10000</button>
          </div>

        </div>
      </div>

    </template>

  </money-modal>
</template>

<script>
import { useMeta } from 'vue-meta'
import IMask from 'imask';
import { mapGetters, mapActions } from "vuex";
import jwt_decode from "jwt-decode";
export default {
  setup() {
    useMeta(
      {
        title: 'Профиль',
        description: [
          { content: 'cs-market, кейсы, маркетплейс, магазин, профиль' }
        ]
      }
    )
  },
  data() {
    return {
      userData: 1,
      isIn: true,
      cardPick: false,
      userName: null,
      userBalance: null,
      payment: "",
      paySum: null,
      outSum: null,
      lastTrans: null,
      ip: null,
      phone: '',
      balancemob: [
        {
          id: 1,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: true,
          type: 'Пополнение',
        },
        {
          id: 2,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: true,
          type: 'Вывод',
        },
        {
          id: 3,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: false,
          type: 'Покупка',
        },
        {
          id: 4,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: true,
          type: 'Продажа',
        },
        {
          id: 1,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: true,
          type: 'Пополнение',
        },
        {
          id: 2,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: true,
          type: 'Вывод',
        },
        {
          id: 3,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: false,
          type: 'Покупка',
        },
        {
          id: 4,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: true,
          type: 'Продажа',
        },
        {
          id: 1,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: true,
          type: 'Пополнение',
        },
        {
          id: 2,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: true,
          type: 'Вывод',
        },
        {
          id: 3,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: false,
          type: 'Покупка',
        },
        {
          id: 4,
          date: '05.12.22',
          time: '14:25',
          sum: 8000,
          balance: 18000,
          status: true,
          type: 'Продажа',
        },
      ],

      transmob: [
        {
          id: 1,
          name: 'Tec-9 | Fuel Injector',
          date: '05.12.22',
          type: true,
          status: true,
          img: require('@/assets/img/tectable.png'),
          price: 15980
        },
        {
          id: 1,
          name: 'Tec-9 | Fuel Injector',
          date: '05.12.22',
          type: true,
          status: true,
          img: require('@/assets/img/tectable.png'),
          price: 15980
        },
        {
          id: 2,
          name: 'Tec-9 | Fuel Injector',
          date: '05.12.22',
          type: false,
          status: false,
          img: require('@/assets/img/tectable.png'),
          price: 15980
        },
        {
          id: 3,
          name: 'Tec-9 | Fuel Injector',
          date: '05.12.22',
          type: false,
          status: 'no',
          img: require('@/assets/img/tectable.png'),
          price: 0
        },
        {
          id: 1,
          name: 'Tec-9 | Fuel Injector',
          date: '05.12.22',
          type: true,
          status: true,
          img: require('@/assets/img/tectable.png'),
          price: 15980
        },
        {
          id: 2,
          name: 'Tec-9 | Fuel Injector',
          date: '05.12.22',
          type: false,
          status: false,
          img: require('@/assets/img/tectable.png'),
          price: 15980
        },
        {
          id: 3,
          name: 'Tec-9 | Fuel Injector',
          date: '05.12.22',
          type: false,
          status: 'no',
          img: require('@/assets/img/tectable.png'),
          price: 0
        },
        {
          id: 2,
          name: 'Tec-9 | Fuel Injector',
          date: '05.12.22',
          type: false,
          status: false,
          img: require('@/assets/img/tectable.png'),
          price: 15980
        },
        {
          id: 3,
          name: 'Tec-9 | Fuel Injector',
          date: '05.12.22',
          type: false,
          status: 'no',
          img: require('@/assets/img/tectable.png'),
          price: 0
        },
      ],

      balance: [
        {
          type: 'Пополнение',
          price: 8800,
          date: '05.12.22',
          time: '15:47',
          status: true,
          bankStatus: 15000,
        },
        {
          type: 'Вывод',
          price: 8800,
          date: '05.12.22',
          time: '15:47',
          status: true,
          bankStatus: 15000,
        },
        {
          type: 'Покупка',
          price: 8800,
          date: '05.12.22',
          time: '15:47',
          status: false,
          bankStatus: 15000,
        },
        {
          type: 'Продажа',
          price: 8800,
          date: '05.12.22',
          time: '15:47',
          status: true,
          bankStatus: 15000,
        },
        {
          type: 'Покупка',
          price: 8800,
          date: '05.12.22',
          time: '15:47',
          status: true,
          bankStatus: 15000,
        },
        {
          type: 'Вывод',
          price: 8800,
          date: '05.12.22',
          time: '15:47',
          status: false,
          bankStatus: 15000,
        },
        {
          type: 'Покупка',
          price: 8800,
          date: '05.12.22',
          time: '15:47',
          status: true,
          bankStatus: 15000,
        },
        {
          type: 'Пополнение',
          price: 8800,
          date: '05.12.22',
          time: '15:47',
          status: true,
          bankStatus: 15000,
        },
        {
          type: 'Пополнение',
          price: 8800,
          date: '05.12.22',
          time: '15:47',
          status: true,
          bankStatus: 15000,
        },
      ],

      cards: [
        {
          id: 1,
          number: "Карта *0482",
          status: false,
        },
        {
          id: 2,
          number: "Карта *8408",
          status: true,
        },
      ],

      trans: [
        {
          id: 1,
          type: false,
          img: require("@/assets/img/tectable.png"),
          name: 'Tec-9 | Fuel Injector',
          count: 2,
          sum: 0,
          price: 7990,
          date: '05.12.22',
          status: true
        },
        {
          id: 2,
          type: true,
          img: require("@/assets/img/tectable.png"),
          name: 'Tec-9 | Fuel Injector',
          count: 4,
          sum: 0,
          price: 7990,
          date: '05.02.22',
          status: true
        },
        {
          id: 3,
          type: false,
          img: require("@/assets/img/tectable.png"),
          name: 'Tec-9 | Fuel Injector',
          count: 2,
          sum: 0,
          price: 5990,
          date: '05.12.22',
          status: false
        },
        {
          id: 4,
          type: false,
          img: require("@/assets/img/tectable.png"),
          name: 'Tec-9 | Fuel Injector',
          count: 2,
          sum: 0,
          price: 3990,
          date: '05.12.22',
          status: true
        },

      ],
      balanceReal: [],
    }
  },
  computed: {
    ...mapGetters(["IsLogged", "USER_NAME"]),


  },
  methods: {


    phoneOut() {
      let card = document.querySelector('#cardType2')
      let phone = document.querySelector('#phoneType2')
      let number = document.querySelector('#tel')
      let span = document.querySelector('#outBtn')
      let spin = document.querySelector('.outSpinner')
      let button = document.querySelector('#outButton')
      const amount = this.lastTrans.amount
      const reference = this.lastTrans.reference

      if (this.outSum <= amount) {
        var token = localStorage.getItem('userName')
        axios
          .post('https://cs-market.kg/api/vision_return/', { cost: this.outSum, jwt_token: token, ip: '0.0.0.0', site_name: 'megacase', site_url: 'megacase.kz' })
          .then((res) => {
            console.log(res)
            // window.location.href = res.data.url
          })
          .catch((error) => {
            console.error(error);
            console.log('Успех')
          });


      }
      else {
        alert(`Сумма должна быть не больше ${amount}`)
      }
    },
    phonePur() {
      let span = document.querySelector('#inBtn')
      let spin = document.querySelector('.inSpinner')
      let card = document.querySelector('#cardType')
      let phone = document.querySelector('#phoneType')
      let button = document.querySelector('#inButton')

      if (this.paySum >= 200) {
        this.ipGet()

        var token = localStorage.getItem('userName')
        axios
          .post('https://cs-market.kg/api/vision_pay/', { cost: this.paySum, jwt_token: token, ip: '0.0.0.0', site_name: 'megacase', site_url: 'megacase.kz' })
          .then((res) => {
            console.log(res)
            window.location.href = res.data.url
          })
          .catch((error) => {
            console.error(error);
          });

        span.style.display = 'None'
        button.disabled = true
        spin.classList.add('spinnerActive')

        setTimeout(() => {
          span.style.display = 'block'
          button.disabled = false
          spin.classList.remove('spinnerActive')
        }, 30000);

      }
      else {
        alert('Сумма должна быть не меньше 200')
      }

    },
    withdrawal() {
      if (this.outSum != '') {
        var token = localStorage.getItem('userName')
        axios
          .post('https://cs-market.kg/api/vision_return/', { cost: this.outSum, jwt_token: token, ip: '0.0.0.0', site_name: 'megacase', site_url: 'megacase.kz' })
          .then((res) => {
            console.log(res)
            //  window.location.href = res.data.url
          })
          .catch((error) => {
            console.error(error);
          });
      }
      else {
        alert('Введите сумму для вывода!')
      }
    },
    purchase() {
      this.ipGet()
      if (this.paySum != '') {
        var token = localStorage.getItem('userName')
        axios
          .post('https://cs-market.kg/api/vision_pay/', { cost: this.inSum, jwt_token: token, ip: '0.0.0.0', site_name: 'megacase', site_url: 'megacase.kz' })
          .then((res) => {
            console.log(res)
            window.location.href = res.data.url
          })
          .catch((error) => {
            console.error(error);
          });
      }
      else {
        alert('Введите сумму пополнения!')
      }
    },
    ipGet() {
      axios({
        method: 'get',
        url: 'https://api.ipify.org'
      })
        .then((response) => {
          this.ip = response.data
        })
    },
    closeMoney() {
      $("#moneyModal").modal("hide");
    },
    copy() {
      var small = document.querySelector('#copyed')
      var text = document.querySelector('#trade')
      text.select()
      document.execCommand('copy');
      small.style.display = 'block'
    },
    showPass() {
      let input = document.querySelector('#newpass')
      const type = input.getAttribute('type') === 'password' ? 'text' : 'password'
      input.setAttribute('type', type)
    },
    showRepeat() {
      let input = document.querySelector('#repeatpass')
      const type = input.getAttribute('type') === 'password' ? 'text' : 'password'
      input.setAttribute('type', type)
    }
  },
  created() {
    if (this.IsLogged == 'true') {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);

      this.userName = decoded.name
      this.userBalance = decoded.balance
    }
    else {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);

      this.userName = decoded.e_mail
      this.userBalance = decoded.balance
    }

    if (this.IsLogged == 'true') {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);
      axios({
        method: 'get',
        url: `https://cs-market.kg/api/get_user_openid/?open_id=${decoded.open_id}&site_name=megacase`,
      })
        .then((response) => {
          if (response.data.jwt_token) {
            console.log(response.data)
            this.balanceReal = response.data.transactions
            const lastObject = this.balanceReal[this.balanceReal.length - 1];
            this.lastTrans = lastObject
            localStorage.setItem("userName", response.data.jwt_token);


            var token = localStorage.getItem('userName')
            var decoded = jwt_decode(token);

            this.userName = decoded.name
            this.userBalance = decoded.balance
          }
          else {
            return
          }
        })
        .catch((error) => {

          console.error(error);
        })
    }
    else {
      var token = localStorage.getItem('userName')
      var decoded = jwt_decode(token);

      const path = "https://cs-market.kg/api/refresh_token";
      axios
        .post(path, { email: decoded.email, password: decoded.password, site_name: 'megacase' })
        .then((res) => {
          console.log(res)
          const trans = res.data.transactions
          const lastObject = trans[trans.length - 1];
          this.lastTrans = lastObject
          localStorage.setItem("userName", res.data.jwt_token);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}

</script>


<style scoped>
.xdkek {
  font-family: var(--nun);
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.form-check {
  display: flex;
  align-items: center;
}

.notSteam__body button {
  background: #AA001A;
  border-radius: 5px;
  border: 0;
  width: 100%;
  padding: 16px 0;
  margin-top: 37px;

  font-family: var(--nun);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

.notSteam__body p {
  font-family: var(--nun);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  margin-top: 38px;
}

.notSteam__body h1 {
  font-family: var(--unb);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-top: 50px;
}

.notSteam {
  padding-top: 200px;
  display: flex;
  justify-content: center;
}

.notSteam__body {
  background: #141721;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 47px 40px 56px 40px;
}

.trmsale {
  width: 100%;
  padding: 18px 0;
  background: rgba(170, 0, 26, 0.4);
  border: 1px solid #AA001A;
  border-radius: 5px;

  font-family: var(--nun);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

.trmshop {
  width: 100%;
  background: #AA001A;
  border-radius: 5px;
  border: 0;
  margin-top: 45px;
  margin-bottom: 12px;
  padding: 18px 0;

  font-family: var(--nun);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;

}

.transmob__body h1,
.balancemob h1 {
  font-family: var(--nun);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

.trsale {
  background: rgba(170, 0, 26, 0.4);
  border: 1px solid #AA001A;
  border-radius: 5px;
  padding: 24px 50px;

  font-family: var(--nun);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  margin-left: 23px;
}

.trshop {
  background: #AA001A;
  border-radius: 5px;
  border: 0;
  padding: 24px 50px;

  font-family: var(--nun);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

.transerror h1,
.balance__body h1 {
  font-family: var(--unb);
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  color: #fff;
  margin-top: 50px;
  margin-bottom: 50px;
}

.spinner-border {
  margin-left: 40%;
}

button {
  transition: all .3s ease;
}

.spinnerActive {
  display: block !important;
}

.outSpinner,
.inSpinner {
  display: none;
}

#tel {
  background: transparent;
  background: transparent;
  border: 0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #AA001A;
  border-radius: 5px !important;
  color: #fff;
  font-family: var(--nun);
  font-size: 16px;
}

.moneyout {
  background: rgba(170, 0, 26, 0.4);
  border: 1px solid #AA001A;
  border-radius: 5px;
  padding: 18px 0;
  width: 100%;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--nun);
  color: #FFFFFF;
  margin-top: 13px;
}

.moneyin {
  border: 0;
  background: #AA001A;
  border-radius: 5px;
  padding: 18px 0;
  width: 100%;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--nun);
  color: #FFFFFF;
}

.mobbtn {
  display: none;
}

.typepay input {
  height: 25px;
  width: 25px;
}

.typepay label {
  margin-bottom: 0;
  margin-left: 16px;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  font-family: var(--unb);

}

td {
  white-space: nowrap;
}

.cartatrap button {
  display: none;
}

.balancemob__money {
  justify-content: space-evenly;
}

.balancemob__money p {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--nun);
  color: #fff;
}

.balancemob__money span {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-family: var(--unb);
}

.balancemob__status p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  font-family: var(--nun);
}

.balancemob__date p {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  font-family: var(--nun);
}

.balancemob {
  display: none;
}

.transmob__body::-webkit-scrollbar-track {
  background-color: #181820;
}

.transmob__body::-webkit-scrollbar {
  width: 10px;
}

.transmob__body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px #2C6DF5;
}

.balancemob::-webkit-scrollbar-track {
  background-color: #181820;
}

.balancemob::-webkit-scrollbar {
  width: 10px;
}

.balancemob::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px #2C6DF5;
}

.transmob__body,
.balancemob {
  height: 1020px;
  padding-right: 15px;
  padding-left: 10px;
  overflow-y: auto;
}

.transmob__desc--nosuc {
  color: #F61E2B !important;
}

.transmob__desc--suc {
  color: #05EA2A !important;
}

.transmob__desc small {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: var(--unb);
  color: #fff;

}

.transmob__desc span {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  font-family: var(--nun);
}

.transmob__desc p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--nun);
  color: #fff;
}

.transmob__img {
  background: #313A53;
  box-shadow: 0px 0px 6px #2C6DF5;
  border-radius: 5px;
  padding: 2px 11px;
}

.transmob__body {
  display: none;
}

.userinfomob a {
  font-family: var(--nun);
  color: #FFFFFF;
  opacity: 0.5;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.userinfomob span {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  font-family: var(--unb);
  color: #fff;
  margin-bottom: 6px !important;
}

.userinfomob p {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  font-family: var(--nun);
  color: #fff;
  margin-bottom: 6px !important;
}

.usermob {
  display: none;
}

.imgmod {
  margin-right: 16px;
}

.countActive {
  background: #AA001A !important;
}

.moneymodal__bodycounts {
  margin-left: -23px !important;
}

.footermod {
  padding: 0 50px 40px 50px;
}

.plsnot {
  margin-left: 0 !important;
}

.moneymodal__bodycounts button {
  margin-left: 10px;
  max-width: 113px;
}

.moneymodal__bodycounts button {
  border: 2px solid #AA001A;
  border-radius: 5px;
  font-family: var(--unb);
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  background: transparent;

  padding: 12px 20px;
}

.moneymodal__bodyfot button {
  border: 0;
  background: #AA001A;
  border-radius: 5px;
  padding: 16px 20px;

  font-family: var(--nun);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  width: 45%;
}

.moneymodal__bodyfot input {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #AA001A;
  border-radius: 0 !important;
  max-width: 208px;

  color: #fff;
  font-family: var(--unb);
  font-weight: 400;
  font-size: 28px;
  line-height: 24px;
}

.moneymodal__bodyfot input::placeholder {
  font-size: 20px;
}

.moneymodal__body label {
  color: #fff;
  font-family: var(--nun);
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  margin-left: 32px;
}

.moneymodal__body a {
  color: #fff;
  text-decoration: underline;
}

.form-check-input {
  background-color: green;
  width: 30px;
  height: 30px;
  transform: none !important;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);

}

.moneymodal__bodyCardPick h1 {
  font-family: var(--unb);
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin: 0;
}

.moneymodal__bodyCardPick button {
  font-family: var(--nun);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #fff;

  padding: 8px 20px;
  background: #2C6DF5;
  border-radius: 5px;
}

.moneymodal__bodyCard button {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #2C6DF5;
  border-radius: 5px;
  border: 0;

  font-family: var(--nun);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

.moneymodal__body p {
  font-family: var(--nun);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.moneymodal__body h2 {
  font-family: var(--nun);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.moneymodal__body {
  padding: 0 50px 40px 50px;
}

.moneymodal__header .activeBtn {
  background: #AA001A !important;
  border-radius: 5px !important;
  border-top-right-radius: 5 !important;
  border-bottom-right-radius: 5 !important;
  border-top-left-radius: 5 !important;
  border-bottom-left-radius: 5 !important;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.balancebtn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 !important;
}

.outputbtn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
}

.moneymodal__headerType button {
  font-family: var(--nun);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fff;

  padding: 10px 57px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  transition: all .3s ease;
}

.moneymodal__header {
  padding: 40px 50px;
}

.moneymodal__header h1 {
  font-family: var(--unb);
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
}

.table__img {
  background: #313A53;
  box-shadow: 0px 0px 6px #2C6DF5;
  border-radius: 5px;
  padding: 5px 19px;
  margin-right: 31px;
}

.table__name {
  display: flex;
  text-transform: uppercase;
}

table th {
  opacity: 0.5;
}

table th,
table td {
  font-family: var(--nun);
  color: #fff;
}

.balance__body::-webkit-scrollbar,
.transactions__body::-webkit-scrollbar {
  background: #23252C;
  border-radius: 10px;
  width: 10px;
}

.balance__body::-webkit-scrollbar-thumb,
.transactions__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #898B90;

}

.balance__body::-webkit-scrollbar-track,
.transactions__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #23252C;

}

.balance__body {
  height: 450px;
  overflow-y: auto;
  background: #2B2E37;
  border-radius: 5px;
  box-shadow: 0px 0px 6px #272525;
  padding: 33px 54px 50px 54px;
}


.transactions__body {
  height: 500px;
  overflow-y: auto;
  background: #2B2E37;
  box-shadow: 0px 0px 6px #272525;
  border-radius: 5px;
  padding: 33px 54px 50px 54px;
}

.true {
  color: #05EA2A;
}

.false {
  color: #F61E2B;
}

.edit {
  position: absolute;
  top: 30%;
  right: 3%;
}

.status {
  position: absolute;
  top: 30%;
  left: 40%;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-family: var(--nun);
}

.carta {
  position: relative;
  width: 650px;
}

.carta input {
  width: 100% !important;
}

.savetrade {
  width: 511px;
  padding: 24px 0;
  background: #AA001A;
  border-radius: 5px;

  font-family: var(--nun);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

.form__trade small {
  color: green;
  font-family: var(--nun);
  display: none;
}

.trade-link {
  width: 811px;
  position: relative;
}

.form__trade a {
  margin: 0;
  color: #fff;
  font-family: var(--nun);
  text-decoration: underline;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.form__trade input {
  width: 811px !important;
}

.password__btn {
  width: 511px;
  background: #AA001A;
  border-radius: 5px;
  padding: 24px 0 24px 0;

  font-family: var(--nun);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

.data__password label {
  color: #fff;
  font-family: var(--nun);
  font-size: 24px;
  font-weight: 500;
}

.data__settings {
  width: 511px;
  position: relative;
}

.data__password input {
  background: #272525;
  border: 0;
  font-family: var(--nun) !important;
  color: #fff;
  border-radius: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0px 0px 4px #AA001A;
}

.data__password input::placeholder {
  color: rgba(255, 255, 255, 0.3);
  font-family: var(--nun) !important;
}

.data__all {
  padding: 30px 0 650px 0;
  position: relative;
}

.data__password,
.transactions,
.balance {
  opacity: 0;
  position: absolute;
  left: 2000px;
  transition: .9s ease;
}

.balance {
  width: 100%;
}

.transactions {
  width: 100%;
}

.data__password {
  width: 100%;
  padding: 0 90px 0 90px;
}

.data--active {
  opacity: 1;
  left: 0;


}

.data__user {
  padding: 60px 0;
  max-height: 120px;
}

.data__user__btn1 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.data__user__btn2 {
  border-radius: 0 !important;
}

.data__user__btn3 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.data__user button {
  font-family: var(--unb);
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #fff;

  background: #2B2E37;
  padding: 17px 42px;
  border: 0;
  border-radius: 10px;
  transition: all .3s ease;
}

.data__user__btn--active {
  padding: 27px 42px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 6px #272525;
  z-index: 1;
}

.auth {
  text-align: center;
  font-size: 6vw;
  color: #fff;
  padding-top: 9vw;
}

.howget {
  font-weight: 400;
  font-size: 0.83vw;
  text-decoration: underline;
  color: #fff;
  margin-right: 1.4vw;
}

.chain {
  position: absolute;
  left: -2.2vw;
}

::placeholder {
  font-size: 0.8vw;
}

.main {
  padding-top: 9.38vw;
  padding-left: 5.21vw;
  padding-right: 5.21vw;
  overflow: hidden;
}

.lc {
  font-weight: 500;
  font-size: 2.08vw;
  text-align: center;
  color: #fff;
  font-family: var(--unb);
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem;
}

.user__info {
  display: flex;
  align-items: center;
}

.user__info img {
  max-width: 8.33vw;
  border-radius: 100%;
}

.user__info-group {
  display: flex;
  padding-left: 2rem;
  padding-bottom: 2rem;
  align-items: center;
}

.user__info-group a {
  padding: 0.5rem;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.25vw;
  color: #fff;
  opacity: 0.5;
  line-height: 1.46vw;
  font-family: var(--nun);
}

.user__info-name {
  padding: 1rem 0.7rem;
  font-weight: 600;
  font-size: 1.67vw;
  line-height: 1.67vw;
  color: #fff;
  font-family: var(--unb);
}

.user__credits {
  display: flex;
  height: 8.33vw;
  align-items: center;
  flex-wrap: wrap;
}

.user__credits-money {
  display: flex;
  min-width: 8.33vw;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 2rem;
}

.user__credits-money p {
  font-weight: 500;
  font-size: 2.08vw;
  line-height: 1.25vw;
  color: #fff;
  font-family: var(--unb);
}

.user__credits-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* align-content: center; */
  height: 7.29vw;
}

.user__btn {
  text-align: center;
  color: #fff;
  border-radius: 5px;
  background-color: #AA001A;
  border: 1px solid #AA001A;
  display: flex;
  align-content: center;
  justify-content: center;
  column-gap: 1.04vw;
  align-items: center;
  font-size: 1.04vw;
  line-height: 1.2vw;
  padding: 0.83vw 2.08vw 0.83vw 2.08vw;
  font-family: var(--nun);
}

.user__credits-buttons button {
  min-width: 14.58vw;
  box-sizing: border-box;
}

hr {
  background: rgba(255, 255, 255, 0.1);
  opacity: 0.1;
}

.user__settings {
  margin: 0 2rem;
}

.user__links {
  display: flex;
  flex-wrap: wrap;
  gap: 3em;
}

.user__links-trade {
  padding: 1rem 0;
  display: flex;
  width: 50%;
  gap: 1rem;
  flex-direction: column;
}

.user__links-trade>div {
  display: flex;
  justify-content: space-between;
}

.user-input {
  height: 4.08vw;
  width: 100%;
  border: 0;
  padding: 5px;
  color: rgba(255, 255, 255, 0.3);
  background: #141721;
  border: 1px solid #246bff;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #2c6df5;
}

.title {
  font-weight: 500;
  font-size: 1.04vw;
  line-height: 1.25vw;
  color: #fff;
}

.pt-pb {
  padding: 2rem 0;
}

.user__links-trade img {
  position: relative;
  right: 0;
}

.user__links-email {
  display: flex;
  flex-direction: column;
  width: 18.23vw;
  gap: 1rem;
}

.user__links-email div {
  display: flex;
  justify-content: space-between;
}

.user__password {
  display: flex;
  width: 26.04vw;
  flex-direction: column;
  gap: 1em;
}

.user__password-input {
  display: flex;
  flex-direction: column;
  gap: 1em;
}



.pd-1 {
  padding: 0.5em 0;
}

@media (max-width: 1440px) {


  .trade-link,
  .form__trade input {
    width: 650px !important;
    position: relative;
  }
}

@media (max-width: 1200px) {
  .data__all {
    padding: 30px 0 1200px 0;
  }

  .trade-link {
    margin-top: 20px;
  }

  .main {
    padding-left: 2.21vw;
    padding-right: 2.21vw;
  }

  .data__user button {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .edit {
    right: 25%;
  }

  .status {
    left: 20%;
  }

  .carta input {
    width: 511px !important;
  }

  .trade-link,
  .form__trade input {
    width: 511px !important;
    position: relative;
  }
}

@media (max-width: 768px) {
  ::placeholder {
    font-size: 1.84vw;
  }

  .data__user button {
    font-size: 16px;
  }

  table td {
    font-size: 12px;
    white-space: nowrap;
  }

  .form__trade input {
    width: 511px !important;
  }

  .carta input {
    width: 511px !important;
  }

  .status {
    left: 20%;
    font-size: 14px;
  }

  .edit {
    right: 25%;
  }

  .user__info-name {
    font-size: 2.67vw;
  }

  .user__info-group a {
    font-size: 1.87vw;
  }

  .user__btn {
    font-size: 2vw;
    padding: 1.23vw 2.08vw 1.23vw 2.08vw;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .mobbtn {
    display: block;
  }

  .moneymodal__body h2 {

    font-size: 16px;

  }

  .moneymodal__bodyfot input {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #AA001A;
    border-radius: 0 !important;
    max-width: 130px;
    color: #fff;
    font-family: var(--unb);
    font-weight: 400;
    font-size: 20px;
    font-size: 2;
  }

  .moneymodal__body p {
    font-size: 14px;
  }

  .footermod {
    padding: 0 20px 20px 20px;
  }

  .moneymodal__bodyfot button {
    border: 0;
    background: #AA001A;
    border-radius: 5px;
    padding: 14px 0;
    font-family: var(--nun);
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #fff;
  }

  .moneymodal__headerType button {
    padding: 0px 50px;
    font-size: 14px;
  }

  .moneymodal__body {
    padding: 0 20px 40px 20px;
  }

  .moneymodal__bodycounts {
    display: none !important;
  }

  .cartatrap button {
    display: block;
    background: #2C6DF5;
    border-radius: 5px;
    padding: 15px 0;
    color: #fff;

    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    font-family: var(--nun);
  }

  .data__all {
    padding: 30px 0 1160px 0;
  }

  .balance__body {
    display: none;
  }

  .balancemob {
    display: block;
  }

  .transactions__body {
    display: none;
  }

  .transmob__body {
    display: block;
  }

  .trade-link {
    width: 100%;
  }


  .edit {
    right: 47%;
  }

  .data__settings,
  .password__btn,
  .form__trade input,
  .savetrade,
  .carta input {
    width: 100% !important;
    max-width: 359px;
  }

  ::placeholder {
    font-size: 16px;
  }

  .data__user__btn--active {
    padding: 20px 42px !important;
  }

  .data__password {
    padding: 0;
    margin-left: 1px;
  }

  .data__user button {
    font-size: 14px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px !important;
  }

  .data__user {
    flex-direction: column;
    padding-top: 150px;
    padding-bottom: 150px;

  }

  .user {
    display: none
  }

  .usermob {
    display: block;
  }

  .lc {
    padding-top: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    font-family: var(--unb);
  }

  .user__count {
    font-size: 4vw !important;
    margin-bottom: 7vw;
  }

  .user__margin {
    margin-left: 7vw !important;
  }

  .steam__ico {
    width: 4vw !important;
  }

  .user__btn img {
    width: 3.5vw !important;
  }

  .user__btn {
    font-size: 2vw;
    margin-bottom: 1vw;
  }

  .user__info-name,
  .steam__href,
  .title,
  .howget {
    font-size: 2vw !important;
  }

  .howget {
    margin-top: -1vw;
  }

  .user__password-input img {
    width: 3vw !important;
    margin-top: -5vw;
  }

  .user-input {
    height: 6vw;
  }

  .user__info-name {
    margin-left: -2vw;
  }

  .user__info img {
    max-width: 13vw;
  }

  .user__info-group {
    padding-bottom: 0;
    padding-left: 1rem;
  }

  .user__ava {
    margin-left: 2vw;
    margin-top: 15vw;
    width: 20vw !important;
  }

  .savetrade,
  .password__btn {
    font-size: 14px;
    padding: 15px 0;
  }

  .notSteam {
    padding: 100px 20px;
  }

  .notSteam__body {
    padding: 20px;
  }

  .notSteam__body h1 {
    font-size: 18px;
    line-height: 20px;
  }

  .notSteam__body p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 390px) {
  .moneymodal__bodyfot button {

    padding: 14px 0;

  }

  .data__settings,
  .password__btn,
  .form__trade input,
  .savetrade,
  .carta input {
    width: 100% !important;
    max-width: 325px;
  }

  .edit {
    display: none;
  }


  .data__all {
    padding: 30px 0 1160px 0;
  }
}

@media (max-width: 375px) {

  .data__settings,
  .password__btn,
  .form__trade input,
  .savetrade,
  .carta input {
    width: 100% !important;
    max-width: 311px;
  }

  .moneymodal__bodyCardPick h1 {
    font-size: 16px;
  }

  .carta input {
    font-size: 14px;
  }

  .status {
    left: 16%;
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .moneymodal__headerType button {
    padding: 0px 28px;
  }

  .moneymodal__bodyfot input {
    max-width: 80px;
  }

  .data__settings,
  .password__btn,
  .form__trade input,
  .savetrade,
  .carta input {
    width: 100% !important;
    max-width: 258px;
  }

  .status {
    left: 16%;
    font-size: 11px;
  }

  .trade-link button {
    top: 47% !important;
  }

  label {
    font-size: 14px !important;
  }

  .form__trade a {
    font-size: 14px;
  }

  .data__password input {
    height: 42px;
  }
}
</style>
